import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  AlertOctagon,
  DeviceFloppy,
  Download,
  FileOff,
} from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import FilesList from "../../../../components/files-list";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import GusDownloadModal from "../../../../components/gus-download-modal";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import {
  ControlledInvoiceTable,
  InvoiceTableItem,
} from "../../../../components/invoice-table";
import {
  ControlledAddressesSelect,
  ControlledBankAccountsSelect,
  ControlledBankAccountTypesSelect,
  ControlledCasesSelect,
  ControlledCurrencyExchangeRatesSelect,
  ControlledCurrencySelect,
  ControlledDictionaryValuesSelect,
  ControlledDocumentsSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledOrganizationalUnitUsersSelect,
  ControlledPaymentTypesSelect,
  ControlledUsersOrganizationalUnitsSelect,
  ControlledUsersSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import Tooltip from "../../../../components/tooltip";
import { HIDDEN_ADDRESS, HIDDEN_FILE } from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import {
  Address,
  BankAccount,
  BankAccountType,
  DefaultAddress,
} from "../../../Addresses/addresses.d";
import {
  ADDRESS_SUGGESTION,
  ADDRESS_SUGGESTIONS,
} from "../../../Addresses/addresses.gql";
import { AssignmentLocationState } from "../../../Folders/folders";
import {
  DocumentLink,
  FinancialAccountingDocumentItem,
} from "../../documents.d";
import { FILES_REVEAL } from "../../documents.gql";
import {
  FINANCIAL_ACCOUNTING_DOCUMENTS_FIELDS,
  FinancialAccountingDocumentInputs,
} from "../financial-accounting-document.form";
import {
  FINANCIAL_ACCOUNTING_DOCUMENT_FILES_SHOW,
  FINANCIAL_ACCOUNTING_DOCUMENT_SHOW,
  FINANCIAL_ACCOUNTING_DOCUMENT_UPDATE,
} from "../financial-accounting-document.gql";

export default function DocumentsEditFinancialAccountingDocument(): React.ReactElement {
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [
    isFilesRevealConfirmDialogOpen,
    setIsFilesRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(false);
  const [hasPersonalData, setHasPersonalData] = useState<boolean>();
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([]);
  const [defaultAddress, setDefaultAddress] = useState<DefaultAddress>();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [addressFromGus, setAddressFromGus] = useState<Address>();
  const [isGusDownloadOpen, setIsGusDownloadOpen] = useState(false);
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [availableBankAccounts, setAvailableBankAccounts] = useState<
    BankAccount[]
  >();
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading, isFetching, setIsFetching } = useLoading();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);
  const { user } = useAuth();

  const {
    assignment,

    setAssignment,
    setIsAssignmentActive,
  } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    setValue,
    unregister,
    watch,
  } = useForm<FinancialAccountingDocumentInputs>();

  const [focusedInput, setFocusedInput] = useState<
    keyof FinancialAccountingDocumentInputs
  >();

  const watchNip = watch("addressNip");
  const watchAddress = watch("senderWhenAddress");
  const watchRecipientWhenUser = watch("recipientWhenUser");
  const watchOrganizationalUnit = watch("organizationalUnit");
  const watchHandDelivered = watch("handDelivered");
  const watchCurrency = watch("currencies");
  const watchBankAccount = watch("addressBankAccount");
  const watchAccountType = watch("accountType");
  const watchGrossValue = watch("grossValue");
  const watchFinancialAccountingDocumentItems = watch(
    "financialAccountingDocumentItems"
  );
  const isSensitiveAddressChoosed =
    watchAddress && watchAddress?.[0]?.typename === HIDDEN_ADDRESS;

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (name && type) {
        if (focusedInput !== name)
          setFocusedInput(name as keyof FinancialAccountingDocumentInputs);

        if (
          [
            "payeeName",
            "accountNumber",
            "bankName",
            "bankCode",
            "accountType",
            "accountCurrency",
          ].includes(name) &&
          watchBankAccount?.[0]?.id !== 0
        ) {
          setValue("addressBankAccount", [{ id: 0 }]);
        }
      }

      if (name === "senderWhenAddress") {
        if (type === undefined) {
          getAddressData(values.senderWhenAddress, false);
        } else {
          getAddressData(values.senderWhenAddress, true);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, focusedInput]);

  const { data, error: queryError } = useQuery(
    FINANCIAL_ACCOUNTING_DOCUMENT_SHOW,
    {
      variables: { id: documentId },
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const {
    data: financialAccountingDocumentFilesData,
    refetch: financialAccountingDocumentFilesRefetch,
    error: financialAccountingDocumentFilesError,
  } = useQuery(FINANCIAL_ACCOUNTING_DOCUMENT_FILES_SHOW, {
    variables: { id: documentId },
    fetchPolicy: "no-cache",
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const hasHiddenFiles =
    !!financialAccountingDocumentFilesData?.financialAccountingDocument?.files
      ?.length &&
    financialAccountingDocumentFilesData?.financialAccountingDocument?.files[0]
      ?.__typename === HIDDEN_FILE;

  const { refetch: fetchAddress } = useQuery(ADDRESS_SUGGESTIONS, {
    skip: true,
  });

  const [updateInvoice, { error: mutationError, loading }] = useMutation(
    FINANCIAL_ACCOUNTING_DOCUMENT_UPDATE,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onError = (errors: Record<string, unknown>, e: any) => {
    setFocusedInput(undefined);
    setErrorTimeStamp(e.timeStamp);
  };

  const onSubmit = async ({
    description,
    title,
    programName,
    parentIds,
    documentDate,
    sequenceNumber,
    inflowDate,
    postStampAt,
    senderWhenAddress,
    organizationalUnit,
    documentNumber,
    name,
    handDelivered,
    files,
    addressName,
    addressNip,
    addressCity,
    addressStreetName,
    addressBuildingNumber,
    addressApartmentNumber,
    addressMobileNumber,
    addressPostalCode,
    addressCountryCode,
    addressEmail,
    addressPhoneNumber,
    addressOverwrite,
    addressIsSensitive,
    addressBankAccount,
    paymentAt,
    payeeName,
    accountNumber,
    bankName,
    accountType,
    bankCode,
    financialAccountingDocumentItems,
    invoiceTypes,
    currencies,
    exchangeRates,
    paymentTypes,
    grossValue,
    raksDiaryNumber,
    accountCurrency,
  }: FinancialAccountingDocumentInputs): Promise<void> => {
    setIsLoading(true);
    setFocusedInput(undefined);

    const enteredAddress = {
      addressName,
      addressNip,
      addressStreetName,
      addressBuildingNumber,
      addressApartmentNumber,
      addressMobileNumber,
      addressCity,
      addressCountryCode,
      addressPostalCode,
      addressPhoneNumber,
      addressEmail,
      addressIsSensitive,
      addressBankAccount,
      payeeName,
      accountNumber,
      bankName,
      bankCode,
      accountType,
    };

    const shouldSendAddress =
      !!addressName &&
      !!addressStreetName &&
      !!addressBuildingNumber &&
      !!addressCity &&
      !!addressPostalCode &&
      !!addressCountryCode &&
      JSON.stringify(enteredAddress) !== JSON.stringify(defaultAddress);

    const { financialAccountingDocument } = data;
    const formPaymentType = paymentTypes ? paymentTypes?.[0]?.id : "Transfer";
    const formNetValue = financialAccountingDocumentItems.reduce(
      (a, b) => a + (b.totalAmount ? b.totalAmount : 0),
      0
    );
    const formGrossValue = parseFloat(grossValue as any);
    const formAmountInCurrency =
      exchangeRates && currencies ? exchangeRates?.[0]?.id : null;

    try {
      await updateInvoice({
        variables: {
          financialAccountingDocumentUpdateInput: {
            id: documentId,
            userId: user?.id,
            parentIds: parentIds?.length ? parentIds.map(({ id }) => id) : null,
            ...(documentNumber !==
              financialAccountingDocument?.documentNumber && {
              documentNumber,
            }),
            ...(name !== financialAccountingDocument?.name && {
              name,
            }),
            ...(new Date(documentDate as Date).toISOString() !==
              new Date(
                financialAccountingDocument?.documentDate
              ).toISOString() && {
              documentDate,
            }),
            ...(new Date(inflowDate as Date).toISOString() !==
              new Date(
                financialAccountingDocument?.inflowDate
              ).toISOString() && {
              inflowDate,
            }),
            ...(description !== financialAccountingDocument?.description && {
              description,
            }),
            ...(title !== financialAccountingDocument?.title && {
              title,
            }),
            ...(programName !== financialAccountingDocument?.programName && {
              programName,
            }),
            ...(postStampAt &&
              new Date(postStampAt as Date).toISOString() !==
                financialAccountingDocument?.postStampAt && {
                postStampAt,
              }),
            ...(handDelivered !==
              financialAccountingDocument?.handDelivered.toString() && {
              handDelivered: !!handDelivered,
            }),
            ...(sequenceNumber !==
              financialAccountingDocument?.sequenceNumber && {
              sequenceNumber,
            }),
            ...(senderWhenAddress?.[0]?.id !==
              financialAccountingDocument?.senderId && {
              senderId: senderWhenAddress && senderWhenAddress[0]?.id,
            }),
            ...(organizationalUnit?.[0]?.id !==
              financialAccountingDocument?.organizationalUnit?.id && {
              organizationalUnitId:
                organizationalUnit && organizationalUnit?.[0]?.id,
            }),
            ...(new Date(paymentAt as Date).toISOString() !==
              new Date(
                financialAccountingDocument?.paymentAt
              ).toISOString() && {
              paymentAt,
            }),
            ...(formPaymentType !==
              financialAccountingDocument?.formPaymentType && {
              paymentType: paymentTypes ? paymentTypes?.[0]?.id : "Transfer",
            }),
            ...(formNetValue !== financialAccountingDocument?.netValue && {
              netValue: formNetValue,
            }),
            ...(formGrossValue !== financialAccountingDocument?.grossValue && {
              grossValue: formGrossValue,
            }),
            ...(currencies?.[0]?.id !==
              financialAccountingDocument?.currencyId && {
              currencyId: currencies?.[0]?.currencyId || null,
            }),
            ...(invoiceTypes?.[0]?.id !==
              financialAccountingDocument?.invoiceType && {
              invoiceType: invoiceTypes && invoiceTypes?.[0]?.id,
            }),
            ...(formAmountInCurrency !==
              financialAccountingDocument?.amountInCurrency && {
              amountInCurrency: formAmountInCurrency,
            }),
            ...(exchangeRates?.[0]?.id !==
              financialAccountingDocument?.currencyExchangeRateId && {
              currencyExchangeRateId:
                exchangeRates && currencies && exchangeRates?.[0]?.id,
            }),
            ...(addressBankAccount?.[0]?.id !== 0 &&
              addressBankAccount?.[0]?.id !==
                financialAccountingDocument?.bankAccountId && {
                bankAccountId: addressBankAccount?.[0]?.id,
              }),
            ...(raksDiaryNumber !==
              financialAccountingDocument?.raksDiaryNumber && {
              raksDiaryNumber,
            }),
            deleteFilesIds: selectedFiles.length ? selectedFiles : null,
          },

          files,
          financialAccountingDocumentItemUpdateInputs: financialAccountingDocumentItems.map(
            ({
              name,
              description,
              totalAmount,
              totalAmountWithTax,
              id,
              requestFormItemId,
              tableId,
            }) => ({
              name,
              description,
              totalAmount,
              totalAmountWithTax,
              requestFormItemId: requestFormItemId ? requestFormItemId : null,
              tableId,
              ...(!id?.toString().includes("temp") && { id }),
            })
          ),
          ...(shouldSendAddress && {
            addressInput: {
              name: addressName,
              nip: addressNip && addressNip?.replaceAll("-", "").toString(),
              city: addressCity,
              streetName: addressStreetName,
              buildingNumber: addressBuildingNumber,
              apartmentNumber: addressApartmentNumber,
              mobileNumber: addressMobileNumber,
              postalCode: addressPostalCode,
              countryCode: addressCountryCode && addressCountryCode?.[0]?.id,
              email: addressEmail ? addressEmail : null,
              phoneNumber: addressPhoneNumber ? addressPhoneNumber : null,
              overwrite: !!addressOverwrite,
              isSensitive: !!addressIsSensitive,
              ...(addressBankAccount &&
                addressBankAccount?.[0]?.id === 0 && {
                  bankAccountCreateInput: {
                    payeeName,
                    accountNumber,
                    bankName,
                    accountType: accountType && accountType?.[0]?.id,
                    bankCode: bankCode || "",
                    currency: accountCurrency && accountCurrency?.[0]?.code,
                  },
                }),
            },
          }),
          ...(!shouldSendAddress &&
            addressBankAccount?.[0]?.id === 0 && {
              bankAccountCreateInput: {
                payeeName,
                accountNumber,
                bankName,
                accountType: accountType && accountType?.[0]?.id,
                bankCode: bankCode || null,
                currency: accountCurrency && accountCurrency?.[0]?.code,
              },
            }),
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.goBack();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (financialAccountingDocumentFilesError?.graphQLErrors)
      enqueueSnackbar({
        message: (financialAccountingDocumentFilesError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [financialAccountingDocumentFilesError]);

  const { refetch: revealAddress, loading: revealAddressLoading } = useQuery(
    ADDRESS_SUGGESTION,
    {
      skip: true,
    }
  );

  const onAddressRevealSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await revealAddress({
        id: watchAddress && watchAddress[0] && watchAddress[0].id,
        documentId: documentId,
      });

      const choosedAddress = response?.data?.addressSuggestion;

      if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
      if (choosedAddress?.isSensitive)
        setValue("addressIsSensitive", choosedAddress?.isSensitive);

      if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
      if (choosedAddress?.streetName)
        setValue("addressStreetName", choosedAddress?.streetName);
      if (choosedAddress?.buildingNumber)
        setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
      if (choosedAddress?.apartmentNumber)
        setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
      if (choosedAddress?.mobileNumber)
        setValue("addressMobileNumber", choosedAddress?.mobileNumber);
      if (choosedAddress?.city) setValue("addressCity", choosedAddress?.city);
      if (choosedAddress?.postalCode)
        setValue("addressPostalCode", choosedAddress?.postalCode);
      if (choosedAddress?.countryCode)
        setValue("addressCountryCode", [
          {
            id: choosedAddress?.countryCode,
          },
        ]);
      if (choosedAddress?.phoneNumber)
        setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
      if (choosedAddress?.email)
        setValue("addressEmail", choosedAddress?.email);

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      setIsAnyAddressRevealed(true);
      setIsAddressRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onFilesRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealFiles({
        variables: {
          documentId,
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      financialAccountingDocumentFilesRefetch();
      setIsFilesRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsFilesRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsFetching(true);
  }, []);

  useEffect(() => {
    setAddressFromGus(undefined);

    if (!watchAddress?.length) {
      setHasPersonalData(false);
      setIsAnyAddressRevealed(false);
      setValue("addressBankAccount", []);
      setAvailableBankAccounts([]);
      unregister("addressStreetName");
      unregister("addressBuildingNumber");
      unregister("addressApartmentNumber");
      unregister("addressMobileNumber");
      unregister("addressPostalCode");
      unregister("addressCity");
      unregister("addressCountryCode");

      setAvailableBankAccounts([]);
      setValue("addressBankAccount", []);
      setValue("payeeName", "");
      setValue("accountNumber", "");
      setValue("bankName", "");
      setValue("bankCode", "");
      setValue("accountType", []);
      setValue("accountType", [{ id: BankAccountType.IBAN }]);
      setValue("accountCurrency", []);
    }
  }, [watchAddress]);

  async function getAddressData(
    value: FinancialAccountingDocumentInputs["senderWhenAddress"],
    updateBankAccount: boolean
  ) {
    setValue("addressName", "");
    setValue("addressNip", "");
    setValue("addressStreetName", "");
    setValue("addressBuildingNumber", "");
    setValue("addressApartmentNumber", "");
    setValue("addressMobileNumber", "");
    setValue("addressCity", "");
    setValue("addressPostalCode", "");
    setValue("addressPhoneNumber", "");
    setValue("addressEmail", "");
    setValue("addressIsSensitive", false);

    if (value && value[0] && value[0].id) {
      const response = await fetchAddress({
        filter: {
          id: {
            eq: value[0].id as number,
          },
        },
      });

      const choosedAddress = response?.data?.addressSuggestions?.nodes[0];

      if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
      if (choosedAddress?.__typename === "HiddenAddress") {
        setValue("addressIsSensitive", true);
        setValue("addressCountryCode", []);
        setHasPersonalData(true);
        setAvailableBankAccounts(choosedAddress?.userBankAccounts);
      }
      if (choosedAddress?.__typename === "Address") {
        setHasPersonalData(false);
        setValue("addressIsSensitive", choosedAddress?.isSensitive);
        setAvailableBankAccounts(choosedAddress?.bankAccounts);
      }

      if (
        (hasPersonalData && isAnyAddressRevealed) ||
        !hasPersonalData ||
        value[0]?.typename !== "HiddenAddress"
      ) {
        if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
        if (choosedAddress?.streetName)
          setValue("addressStreetName", choosedAddress?.streetName);
        if (choosedAddress?.buildingNumber)
          setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
        if (choosedAddress?.apartmentNumber)
          setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
        if (choosedAddress?.mobileNumber)
          setValue("addressMobileNumber", choosedAddress?.mobileNumber);
        if (choosedAddress?.city) setValue("addressCity", choosedAddress?.city);
        if (choosedAddress?.postalCode)
          setValue("addressPostalCode", choosedAddress?.postalCode);
        if (choosedAddress?.countryCode)
          setValue("addressCountryCode", [
            {
              id: choosedAddress?.countryCode,
            },
          ]);
        if (choosedAddress?.phoneNumber)
          setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
        if (choosedAddress?.email)
          setValue("addressEmail", choosedAddress?.email);

        if (choosedAddress?.isSensitive)
          setValue("addressIsSensitive", choosedAddress?.isSensitive);

        if (updateBankAccount && choosedAddress?.bankAccounts?.length) {
          setValue("addressBankAccount", [
            {
              ...choosedAddress?.bankAccounts?.[0],
              id: choosedAddress?.bankAccounts?.[0]?.id,
            },
          ]);

          setValue("payeeName", choosedAddress?.bankAccounts?.[0]?.payeeName);
          setValue(
            "accountNumber",
            choosedAddress?.bankAccounts?.[0]?.accountNumber
          );
          setValue("bankName", choosedAddress?.bankAccounts?.[0]?.bankName);
          setValue("bankCode", choosedAddress?.bankAccounts?.[0]?.bankCode);
          choosedAddress?.bankAccounts?.[0]?.accountType &&
            setValue("accountType", [
              {
                id: choosedAddress?.bankAccounts?.[0]?.accountType,
              },
            ]);

          choosedAddress?.bankAccounts?.[0]?.currency !== "PLN" &&
            setValue("accountCurrency", [
              {
                code: choosedAddress?.bankAccounts?.[0]?.currency,
                label: choosedAddress?.bankAccounts?.[0]?.currency,
              },
            ]);
        }
      }

      setDefaultAddress({
        addressName: choosedAddress?.name || "",
        addressNip: choosedAddress?.nip || "",
        addressStreetName: choosedAddress?.streetName || "",
        addressBuildingNumber: choosedAddress?.buildingNumber || "",
        addressApartmentNumber: choosedAddress?.apartmentNumber || "",
        addressMobileNumber: choosedAddress?.mobileNumber || "",
        addressCity: choosedAddress?.city || "",
        addressCountryCode: choosedAddress?.countryCode
          ? [
              {
                id: choosedAddress?.countryCode,
              },
            ]
          : [],
        addressPostalCode: choosedAddress?.postalCode || "",
        addressPhoneNumber: choosedAddress?.phoneNumber || "",
        addressEmail: choosedAddress?.email || "",
        addressIsSensitive: choosedAddress?.__typename === HIDDEN_ADDRESS,
      });
      unregister("addressName");
      unregister("addressStreetName");
      unregister("addressBuildingNumber");
      unregister("addressApartmentNumber");
      unregister("addressMobileNumber");
      unregister("addressPostalCode");
      unregister("addressCity");
      unregister("addressCountryCode");
    }
  }

  useEffect(() => {
    if (data?.financialAccountingDocument?.currentAssignment) {
      setAssignment(data?.financialAccountingDocument?.currentAssignment);
      setIsAssignmentActive(true);
    }
    if (data?.financialAccountingDocument) {
      setIsFetching(false);
      setValue(
        "documentNumber",
        data?.financialAccountingDocument?.documentNumber
      );
      setValue(
        "sequenceNumber",
        data?.financialAccountingDocument?.sequenceNumber
      );
      setValue("name", data?.financialAccountingDocument?.name);
      !!data?.financialAccountingDocument?.documentDate &&
        setValue(
          "documentDate",
          new Date(data?.financialAccountingDocument?.documentDate)
        );
      !!data?.financialAccountingDocument?.inflowDate &&
        setValue(
          "inflowDate",
          new Date(data?.financialAccountingDocument?.inflowDate)
        );
      !!data?.financialAccountingDocument?.paymentAt &&
        setValue(
          "paymentAt",
          new Date(data?.financialAccountingDocument?.paymentAt)
        );
      setValue("netValue", data?.financialAccountingDocument?.netValue);
      setValue("grossValue", data?.financialAccountingDocument?.grossValue);
      setValue("description", data?.financialAccountingDocument?.description);
      setValue("title", data?.financialAccountingDocument?.title);
      setValue("programName", data?.financialAccountingDocument?.programName);
      setValue(
        "handDelivered",
        data?.financialAccountingDocument?.handDelivered
      );
      if (data?.financialAccountingDocument?.case)
        setValue("caseName", [
          {
            id: data?.financialAccountingDocument?.case?.id,
            label: `[${data?.financialAccountingDocument?.case?.number}] ${data?.financialAccountingDocument?.case?.name}`,
            ...data?.financialAccountingDocument?.case,
          },
        ]);
      if (data?.financialAccountingDocument?.documentLinks?.length)
        setValue(
          "parentIds",
          data?.financialAccountingDocument?.documentLinks
            ?.filter(
              ({ type, linkerId }: DocumentLink) =>
                type === "Parent" && parseInt(id as string) === linkerId
            )
            .map(({ linked, linker }: DocumentLink) =>
              parseInt(id as string) === linker.id ? linked : linker
            )
        );

      if (data?.financialAccountingDocument?.raksDiaryNumber)
        setValue(
          "raksDiaryNumber",
          data?.financialAccountingDocument?.raksDiaryNumber
        );
    }
    !!data?.financialAccountingDocument?.postStampAt &&
      setValue(
        "postStampAt",
        new Date(data?.financialAccountingDocument?.postStampAt)
      );
    setValue("senderWhenAddress", [
      {
        id: data?.financialAccountingDocument?.sender?.id,
        label: `${
          data?.financialAccountingDocument?.sender?.name
            ? data?.financialAccountingDocument?.sender?.name + ", "
            : ""
        } ${
          data?.financialAccountingDocument?.sender?.address
            ? data?.financialAccountingDocument?.sender?.address + ", "
            : ""
        } ${
          data?.financialAccountingDocument?.sender?.city
            ? data?.financialAccountingDocument?.sender?.city
            : ""
        }`,
        typename: data?.financialAccountingDocument?.sender?.__typename,
      },
    ]);
    setAvailableBankAccounts(
      data?.financialAccountingDocument?.sender?.userBankAccounts
    );

    setValue("addressBankAccount", [
      {
        id: data?.financialAccountingDocument?.bankAccount?.id,
        ...data?.financialAccountingDocument?.bankAccount,
      },
    ]);

    setValue(
      "addressIsSensitive",
      data?.financialAccountingDocument?.sender?.isSensitive
    );
    if (data?.financialAccountingDocument?.organizationalUnit?.id)
      setValue("organizationalUnit", [
        {
          id: data?.financialAccountingDocument?.organizationalUnit?.id,
        },
      ]);
    if (data?.financialAccountingDocument?.recipient)
      setValue("recipientWhenUser", [
        {
          id: data?.financialAccountingDocument?.recipient?.id,
        },
      ]);
    setHasPersonalData(
      data?.financialAccountingDocument?.sender?.__typename ===
        "HiddenAddress" ||
        data?.financialAccountingDocument?.sender?.isSensitive
    );
    data?.financialAccountingDocument?.currency &&
      setValue("currencies", [
        {
          id: data?.financialAccountingDocument?.currency?.code,
          currencyId: data?.financialAccountingDocument?.currency?.id,
        },
      ]);
    data?.financialAccountingDocument?.currencyExchangeRate &&
      setValue("exchangeRates", [
        {
          id: data?.financialAccountingDocument?.currencyExchangeRate?.id,
        },
      ]);
    data?.financialAccountingDocument?.paymentType &&
      setValue("paymentTypes", [
        { id: data?.financialAccountingDocument?.paymentType },
      ]);
    if (data?.financialAccountingDocument?.financialAccountingDocumentItems) {
      setValue(
        "financialAccountingDocumentItems",
        data?.financialAccountingDocument?.financialAccountingDocumentItems.map(
          (item: FinancialAccountingDocumentItem) => {
            return ({
              id: item?.id,
              originalId: item?.id,
              name: item?.name,
              description: item?.description,
              totalAmount: item?.totalAmount,
              totalAmountWithTax: item?.totalAmountWithTax,
              table: item?.tableId,
              ...(item?.requestFormItem?.requestForm && {
                requestFormId: item?.requestFormItem?.requestForm?.id,
                requestFormItem: {
                  id: item?.requestFormItem?.id,
                  label: `[${item?.requestFormItem?.budgetSymbol}], [${item?.requestFormItem?.categoryPath}] ${item?.requestFormItem?.categoryName}, ${item?.requestFormItem?.budgetName}`,
                },
              }),
            } as unknown) as InvoiceTableItem;
          }
        )
      );
    }

    if (data?.financialAccountingDocument?.invoiceType)
      setValue("invoiceTypes", [
        { id: data?.financialAccountingDocument?.invoiceType },
      ]);
  }, [data]);

  useEffect(() => {
    if (addressFromGus) setValue("senderWhenAddress", undefined);
    if (addressFromGus?.nip) setValue("addressNip", addressFromGus?.nip);
    if (addressFromGus?.name) setValue("addressName", addressFromGus?.name);
    if (addressFromGus?.streetName)
      setValue("addressStreetName", addressFromGus?.streetName);
    if (addressFromGus?.buildingNumber)
      setValue("addressBuildingNumber", addressFromGus?.buildingNumber);
    if (addressFromGus?.apartmentNumber)
      setValue("addressApartmentNumber", addressFromGus?.apartmentNumber);
    if (addressFromGus?.mobileNumber)
      setValue("addressMobileNumber", addressFromGus?.mobileNumber);
    if (addressFromGus?.postalCode)
      setValue("addressPostalCode", addressFromGus?.postalCode);
    if (addressFromGus?.city) setValue("addressCity", addressFromGus?.city);
    if (addressFromGus?.countryCode)
      setValue("addressCountryCode", [{ id: addressFromGus?.countryCode }]);
    if (addressFromGus?.phoneNumber)
      setValue("addressPhoneNumber", addressFromGus?.phoneNumber);
    if (addressFromGus?.email) setValue("addressEmail", addressFromGus?.email);
  }, [addressFromGus]);

  useEffect(() => {
    if (watchHandDelivered) setValue("postStampAt", null);
  }, [watchHandDelivered]);

  useEffect(() => {
    if (errors)
      scrollOnError(errors, FINANCIAL_ACCOUNTING_DOCUMENTS_FIELDS, "edit");
  }, [errorTimeStamp]);

  useEffect(() => {
    if (
      watchBankAccount &&
      watchBankAccount?.[0]?.id !== 0 &&
      watchBankAccount?.length !== 0
    ) {
      setValue("payeeName", watchBankAccount?.[0]?.payeeName || "");
      setValue("accountNumber", watchBankAccount?.[0]?.accountNumber || "");
      setValue("bankName", watchBankAccount?.[0]?.bankName || "");
      setValue("bankCode", watchBankAccount[0]?.bankCode || "");
      watchBankAccount?.[0]?.accountType &&
        setValue("accountType", [{ id: watchBankAccount?.[0]?.accountType }]);
      watchBankAccount?.[0]?.currency !== "PLN"
        ? setValue("accountCurrency", [{ id: watchBankAccount?.[0]?.currency }])
        : setValue("accountCurrency", []);
    }

    if (!watchBankAccount?.length) {
      setValue("payeeName", "");
      setValue("accountNumber", "");
      setValue("bankName", "");
      setValue("bankCode", "");
      setValue("accountType", [{ id: BankAccountType.IBAN }]);
      setValue("accountCurrency", []);
    }
  }, [watchBankAccount]);

  const shouldDisableField = (fieldName: string): boolean =>
    !!data?.financialAccountingDocument?.currentStatus?.documentFlowStep &&
    !data?.financialAccountingDocument?.currentStatus?.documentFlowStep?.allowedDocumentFields?.includes(
      fieldName
    ) &&
    !data?.financialAccountingDocument?.currentStatus?.documentFlowStep
      ?.isAllowedToEditAllFields;

  if (
    data &&
    data?.financialAccountingDocument &&
    (!checkPermission(PERMISSIONS.financialAccountingDocument.update) ||
      !data?.financialAccountingDocument?.isEditable)
  )
    <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.financialAccountingDocument?.internalNumber}
        labels={["Dokumenty", "Edytowanie"]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              !(Object.keys(dirtyFields).length <= 1)
                ? setIsCancelConfirmDialogOpen(true)
                : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "editInvoice",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={4}>
            <FormControl label="Numer" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>
                  {data?.financialAccountingDocument?.internalNumber}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={8}>
            <FormControl label="Data rejestracji" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType="datetime">
                  {data?.financialAccountingDocument?.createdAt}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form
          id="editInvoice"
          onSubmit={handleSubmit(onSubmit)}
          onFocus={() => {
            const inputName =
              typeof window !== "undefined" &&
              (window?.document?.activeElement
                ?.id as keyof FinancialAccountingDocumentInputs);

            inputName &&
              inputName !== focusedInput &&
              !inputName.includes("bui") &&
              setFocusedInput(inputName);
          }}
        >
          <Grid>
            {FINANCIAL_ACCOUNTING_DOCUMENTS_FIELDS.filter(
              (g) =>
                g.fields.filter(
                  (f) =>
                    f.edit?.visible &&
                    (!f.documentType ||
                      (f.documentType &&
                        f.documentType.includes(
                          data?.financialAccountingDocument?.documentType.toLowerCase()
                        )))
                ).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                      height="20px"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <LabelMedium>
                          {!!data?.financialAccountingDocument?.files?.length &&
                          group.id === "files"
                            ? `${group.label} (${data?.financialAccountingDocument?.files?.length})`
                            : group.label}
                        </LabelMedium>
                        {hasPersonalData &&
                          !isAnyAddressRevealed &&
                          group.id === "sender" && (
                            <Button
                              size={SIZE.mini}
                              kind={KIND.secondary}
                              $style={{ marginLeft: "10px" }}
                              onClick={() =>
                                setIsAddressRevealConfirmDialogOpen(true)
                              }
                              isLoading={revealAddressLoading || isFetching}
                              type="button"
                            >
                              Pokaż dane
                            </Button>
                          )}
                        {hasHiddenFiles && group.id === "files" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setIsFilesRevealConfirmDialogOpen(true)
                            }
                            isLoading={revealFilesLoading || isFetching}
                            type="button"
                          >
                            Pokaż pliki
                          </Button>
                        )}
                      </Block>
                      {hasPersonalData &&
                        !isAnyAddressRevealed &&
                        group.id === "sender" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "12px" }}
                          >
                            Dane osobowe - podgląd zablokowany
                          </FormattedValue>
                        )}
                      {!hasHiddenFiles &&
                        !!data?.financialAccountingDocument?.files?.length &&
                        group.id === "files" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "13px" }}
                          >
                            Dostęp jest aktywny do końca trwania sesji
                          </FormattedValue>
                        )}
                    </Block>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </>
                </Cell>
              ),
              group.fields
                .filter(
                  (f) =>
                    f.edit?.visible &&
                    (!f.documentType ||
                      (f.documentType &&
                        f.documentType.includes(
                          data?.financialAccountingDocument?.documentType.toLowerCase()
                        )))
                )
                .map((item, index) => (
                  <Cell
                    span={item.span || 6}
                    skip={item.skip || 0}
                    key={group.id + `-field` + index}
                  >
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={
                        (item.id === "filesList" && hasHiddenFiles) ||
                        (!data?.document?.files?.length &&
                          item.id === "filesList")
                          ? ""
                          : item.id === "accountNumber" &&
                            watchAccountType?.[0]?.id === BankAccountType.Other
                          ? ""
                          : item.id === "grossValue" &&
                            parseFloat(
                              watchGrossValue?.toString().replace(",", ".")
                            ) !==
                              parseFloat(
                                watchFinancialAccountingDocumentItems
                                  ?.reduce(
                                    (a, b) => a + (b?.totalAmountWithTax || 0),
                                    0
                                  )
                                  ?.toString()
                              ) &&
                            watchFinancialAccountingDocumentItems?.length > 0
                          ? `<span class="${css({
                              color: theme.colors.negative,
                            })}">Kwota brutto nie zgadza się z sumą kwot brutto pozycji w tabeli.</span>`
                          : item.caption
                      }
                      required={
                        item.info === "possiblyRequired"
                          ? (hasPersonalData && isAnyAddressRevealed) ||
                            !hasPersonalData
                          : item.edit.required
                      }
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (mutationError &&
                          mutationError.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          mutationError.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find((vE: InputValidationError) =>
                              item.id.includes("address")
                                ? vE?.property ===
                                  item.id.slice(7).toLowerCase()
                                : vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading || shouldDisableField(item.id)}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="36px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.PaymentTypesSelect ? (
                        <ControlledPaymentTypesSelect
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.AddressesSelect ? (
                        <ControlledAddressesSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.UsersSelect ? (
                        <ControlledUsersSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.CasesSelect ? (
                        <ControlledCasesSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.CurrencySelect ? (
                        <ControlledCurrencySelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.additionalData === "sensitive"
                              ? "**********"
                              : "PLN"
                          }
                          disabled={
                            (hasPersonalData &&
                              !isAnyAddressRevealed &&
                              item.additionalData === "sensitive") ||
                            shouldDisableField(item.id)
                          }
                          size={SIZE.mini}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.CurrencyExchangeRatesSelect ? (
                        <ControlledCurrencyExchangeRatesSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          size={SIZE.mini}
                          currencyId={watchCurrency?.[0]?.currencyId}
                          disabled={
                            !watchCurrency ||
                            !watchCurrency?.length ||
                            shouldDisableField(item.id)
                          }
                          placeholder=""
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                        <ControlledOrganizationalUnitsSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.DocumentsSelect ? (
                        <ControlledDocumentsSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          filterOptions={(options) =>
                            options.filter((o) => o.id != id)
                          }
                          rules={{
                            validate: (value) => {
                              return value?.[0]?.id?.toString() === id
                                ? "Dokument nie może być nadrzędnym sam dla siebie"
                                : true;
                            },
                          }}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.OrganizationalUnitUsersSelect ? (
                        watchOrganizationalUnit &&
                        watchOrganizationalUnit.length > 0 ? (
                          <ControlledOrganizationalUnitUsersSelect
                            objectId={watchOrganizationalUnit[0].id}
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : (
                          <ControlledUsersSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        )
                      ) : item.type ===
                        FIELDS.UsersOrganizationalUnitsSelect ? (
                        watchRecipientWhenUser &&
                        watchRecipientWhenUser.length > 0 ? (
                          <ControlledUsersOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            objectId={watchRecipientWhenUser[0].id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : (
                          <ControlledOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        )
                      ) : item.type === FIELDS.DictionaryValuesSelect ? (
                        <ControlledDictionaryValuesSelect
                          dictionarySystemName={
                            item.id === "addressCountryCode"
                              ? "countryCodes.alpha2.pl"
                              : item.id === "documentType"
                              ? "documentTypes.incoming"
                              : item.id === "invoiceTypes"
                              ? "invoice.types"
                              : undefined
                          }
                          control={control}
                          name={item.id}
                          id={item.id}
                          disabled={
                            (item.additionalData === "sensitive" &&
                              hasPersonalData &&
                              !isAnyAddressRevealed) ||
                            shouldDisableField(item.id)
                          }
                          placeholder={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.id.includes("address")
                              ? "**********"
                              : "Wybierz"
                          }
                          {...((item.edit.required ||
                            (item.info === "possiblyRequired" &&
                              hasPersonalData &&
                              isAnyAddressRevealed &&
                              item.id.includes("address") &&
                              item.id !== "addressName") ||
                            (item.info === "possiblyRequired" &&
                              !hasPersonalData &&
                              item.id.includes("address") &&
                              item.id !== "addressName")) && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DatePicker ? (
                        <ControlledDatePicker
                          name={item.id}
                          control={control}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={
                            (item.id === "postStampAt" && watchHandDelivered) ||
                            shouldDisableField(item.id)
                          }
                          {...(item.id === "postStampAt" && {
                            $style: { paddingRight: "35px" },
                          })}
                        />
                      ) : item.type === FIELDS.FilesList &&
                        data?.financialAccountingDocument?.files ? (
                        <FilesList
                          files={data?.financialAccountingDocument?.files}
                          hasHiddenFiles={hasHiddenFiles}
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.InvoiceTable ? (
                        <ControlledInvoiceTable
                          control={control}
                          name={item.id}
                          id={item.id}
                          currency={watchCurrency?.[0]?.code}
                          hasFocus={focusedInput === item.id}
                          requestForms={data?.financialAccountingDocument?.financialAccountingDocumentItems?.map(
                            ({
                              requestFormItem,
                            }: FinancialAccountingDocumentItem) =>
                              requestFormItem?.requestForm
                          )}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.FilesPicker ? (
                        <ControlledFilesPicker
                          control={control}
                          name={item.id}
                          maxSize={134217728}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.BankAccountTypesSelect ? (
                        <ControlledBankAccountTypesSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          disabled={
                            (hasPersonalData &&
                              !isAnyAddressRevealed &&
                              item.additionalData === "sensitive") ||
                            shouldDisableField(item.id)
                          }
                        />
                      ) : item.type === FIELDS.BankAccountsSelect ? (
                        <ControlledBankAccountsSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          options={availableBankAccounts}
                          placeholder={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.additionalData === "sensitive"
                              ? "**********"
                              : item.placeholder
                          }
                          disabled={
                            (hasPersonalData &&
                              !isAnyAddressRevealed &&
                              item.additionalData === "sensitive") ||
                            shouldDisableField(item.id)
                          }
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <div className={css({ marginTop: "40px" })}>
                          {item.id === "addressOverwrite" ? (
                            <Tooltip
                              placement="bottomLeft"
                              content={
                                <p>
                                  Zaznaczenie tej opcji spowoduje aktualizację
                                  danych <br /> wybranego adresata w bazie, ale
                                  nie wpłynie to na <br /> wcześniejsze
                                  dokumenty powiązane z tym adresatem.
                                </p>
                              }
                            >
                              <span>
                                <ControlledCheckbox
                                  control={control}
                                  name={item.id}
                                  disabled={
                                    shouldDisableField(item.id) ||
                                    loading ||
                                    (hasPersonalData &&
                                      !isAnyAddressRevealed &&
                                      item.id.includes("address") &&
                                      !item.id.includes("Name"))
                                  }
                                  {...(item.edit.required && {
                                    rules: {
                                      required:
                                        formValidation.messages.required,
                                    },
                                  })}
                                >
                                  {item.label}
                                </ControlledCheckbox>
                              </span>
                            </Tooltip>
                          ) : (
                            <ControlledCheckbox
                              control={control}
                              name={item.id}
                              disabled={
                                shouldDisableField(item.id) ||
                                loading ||
                                (hasPersonalData &&
                                  !isAnyAddressRevealed &&
                                  item.id.includes("address") &&
                                  !item.id.includes("Name"))
                              }
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            >
                              {item.label}
                            </ControlledCheckbox>
                          )}
                        </div>
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.additionalData === "sensitive"
                              ? "**********"
                              : item.id === "accountNumber" &&
                                watchAccountType?.[0]?.id ===
                                  BankAccountType.Other
                              ? ""
                              : item.placeholder
                          }
                          {...((item.edit.required ||
                            (item.info === "possiblyRequired" &&
                              !isSensitiveAddressChoosed)) && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.id === "addressEmail" && {
                            rules: {
                              pattern: {
                                value: formValidation.patterns.email,
                                message: formValidation.messages.incorrectEmail,
                              },
                            },
                          })}
                          type={
                            item.type === FIELDS.NumberInput ? "number" : "text"
                          }
                          disabled={
                            (hasPersonalData &&
                              !isAnyAddressRevealed &&
                              item.additionalData === "sensitive") ||
                            (item.id === "sequenceNumber" &&
                              !checkPermission(
                                PERMISSIONS.document.updateSequenceNumber
                              ))
                          }
                          masked={
                            item.mask !== undefined &&
                            ((item.id === "accountNumber" &&
                              watchAccountType?.[0]?.id ===
                                BankAccountType.IBAN) ||
                              item.id !== "accountNumber")
                          }
                          mask={item.mask}
                          maskChar={item.maskChar || " "}
                          endEnhancer={
                            item.id === "addressNip" ? (
                              <Tooltip content="Pobierz z GUS">
                                <span
                                  onClick={() => setIsGusDownloadOpen(true)}
                                  className={css({ cursor: "pointer" })}
                                >
                                  <Download
                                    size={16}
                                    className={css({
                                      verticalAlign: "middle",
                                      marginLeft: "6px",
                                      display: "inline",
                                    })}
                                  />
                                </span>
                              </Tooltip>
                            ) : item.id === "grossValue" ? (
                              watchCurrency?.[0]?.code || item.endEnhancer
                            ) : (
                              item.endEnhancer
                            )
                          }
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji dokumentu"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isAddressRevealConfirmDialogOpen}
              type={ConfirmDialogType.AddressReveal}
              close={() => setIsAddressRevealConfirmDialogOpen(false)}
              confirm={() => onAddressRevealSubmit()}
            />
            <ConfirmDialog
              isOpen={isFilesRevealConfirmDialogOpen}
              type={ConfirmDialogType.FilesReveal}
              close={() => setIsFilesRevealConfirmDialogOpen(false)}
              confirm={() => onFilesRevealSubmit()}
            />
            <GusDownloadModal
              isOpen={isGusDownloadOpen}
              close={() => setIsGusDownloadOpen(false)}
              confirm={() => null}
              currentNip={watchNip}
              setAddressFromGus={setAddressFromGus}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
