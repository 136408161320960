import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  AlertOctagon,
  DeviceFloppy,
  Download,
  FileOff,
} from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import FilesList from "../../../../components/files-list";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import GusDownloadModal from "../../../../components/gus-download-modal";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import {
  ControlledAddressesSelect,
  ControlledCasesSelect,
  ControlledContractStatusSelect,
  ControlledContractTypeSelect,
  ControlledDictionaryValuesSelect,
  ControlledDocumentsSelect,
  ControlledOrganizationalUnitsSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import Tooltip from "../../../../components/tooltip";
import { HIDDEN_ADDRESS, HIDDEN_FILE } from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { Address, DefaultAddress } from "../../../Addresses/addresses";
import {
  ADDRESS_SUGGESTION,
  ADDRESS_SUGGESTIONS,
} from "../../../Addresses/addresses.gql";
import { AssignmentLocationState } from "../../../Folders/folders";
import { DocumentLink } from "../../documents";
import { FILES_REVEAL } from "../../documents.gql";
import {
  CONTRACT_AGREEMENT_FIELDS,
  ContractAgreementInputs,
} from "../contract-agreement.form";
import {
  CONTRACT_AGREEMENT_FILES_SHOW,
  CONTRACT_AGREEMENT_SHOW,
  CONTRACT_AGREEMENT_UPDATE,
} from "../contract-agreement.gql";

export default function DocumentsEditContractAgreement(): React.ReactElement {
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [
    isFilesRevealConfirmDialogOpen,
    setIsFilesRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(false);
  const [hasPersonalData, setHasPersonalData] = useState<boolean>();
  const [defaultAddress, setDefaultAddress] = useState<DefaultAddress>();
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([]);
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [addressFromGus, setAddressFromGus] = useState<Address>();
  const [isGusDownloadOpen, setIsGusDownloadOpen] = useState(false);
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading, isFetching, setIsFetching } = useLoading();
  const { user } = useAuth();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    unregister,
    watch,
  } = useForm<ContractAgreementInputs>();

  const watchAddress = watch("contractor");
  const watchCountryCode = watch("addressCountryCode");
  const watchBeginAtDate = watch("beginAt");
  const watchEndAtDate = watch("endAt");
  const watchNip = watch("addressNip");

  const { refetch: fetchAddress } = useQuery(ADDRESS_SUGGESTIONS, {
    skip: true,
  });

  const { data, error: queryError } = useQuery(CONTRACT_AGREEMENT_SHOW, {
    variables: { id: documentId },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });
  const {
    data: contractAgreementFilesData,
    refetch: contractAgreementFilesRefetch,
    error: contractAgreementFilesError,
  } = useQuery(CONTRACT_AGREEMENT_FILES_SHOW, {
    variables: { id: documentId },
    fetchPolicy: "no-cache",
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const hasHiddenFiles =
    !!contractAgreementFilesData?.contractAgreement?.files?.length &&
    contractAgreementFilesData?.contractAgreement?.files[0]?.__typename ===
      HIDDEN_FILE;

  const [
    updateContractAgreement,
    { error: mutationError, loading },
  ] = useMutation(CONTRACT_AGREEMENT_UPDATE, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    documentNumber,
    documentDate,
    description,
    parentIds,
    organizationalUnit,
    status,
    sequenceNumber,
    budgetCategory,
    contractType,
    contractNumber,
    legalDocumentsVerification,
    beginAt,
    endAt,
    grossValueInPLN,
    collectionPeriodEndAt,
    legalAct,
    contractor,
    addressName,
    addressNip,
    addressStreetName,
    addressBuildingNumber,
    addressApartmentNumber,
    addressMobileNumber,
    addressCity,
    addressCountryCode,
    addressPostalCode,
    addressPhoneNumber,
    addressEmail,
    addressOverwrite,
    addressIsSensitive,
    files,
  }: ContractAgreementInputs): Promise<void> => {
    setIsLoading(true);

    const enteredAddress = {
      addressName,
      addressNip,
      addressStreetName,
      addressBuildingNumber,
      addressApartmentNumber,
      addressMobileNumber,
      addressCity,
      addressCountryCode,
      addressPostalCode,
      addressPhoneNumber,
      addressEmail,
      addressIsSensitive,
    };

    const shouldSendAddress =
      !!addressName &&
      !!addressStreetName &&
      !!addressBuildingNumber &&
      !!addressCity &&
      !!addressPostalCode &&
      !!addressCountryCode &&
      JSON.stringify(enteredAddress) !== JSON.stringify(defaultAddress);

    const { contractAgreement } = data;

    try {
      await updateContractAgreement({
        variables: {
          contractAgreementUpdateInput: {
            id: documentId,
            userId: user?.id,
            parentIds: parentIds?.length ? parentIds.map(({ id }) => id) : null,
            ...(documentNumber !== contractAgreement?.documentNumber && {
              documentNumber,
            }),
            ...(description !== contractAgreement?.description && {
              description,
            }),
            ...(sequenceNumber !== contractAgreement?.sequenceNumber && {
              sequenceNumber,
            }),
            ...(documentDate &&
              new Date(documentDate as Date).toISOString() !==
                contractAgreement?.documentDate && {
                documentDate,
              }),
            ...(organizationalUnit?.[0]?.id !==
              contractAgreement?.organizationalUnit?.id && {
              organizationalUnitId: organizationalUnit
                ? organizationalUnit[0]?.id
                : user?.organizationalUnitToUsers?.[0]?.organizationalUnit?.id,
            }),
            ...(status?.[0]?.label !== contractAgreement?.status && {
              status: status && status[0]?.label,
            }),
            ...(contractNumber !== contractAgreement?.contractNumber && {
              contractNumber,
            }),
            ...(budgetCategory !== contractAgreement?.budgetCategory && {
              budgetCategory,
            }),
            ...(contractType?.[0]?.label !==
              contractAgreement?.contractType && {
              contractType: contractType && contractType[0]?.label,
            }),
            ...(beginAt &&
              new Date(beginAt as Date).toISOString() !==
                contractAgreement?.beginAt && {
                beginAt,
              }),
            ...(endAt &&
              new Date(beginAt as Date).toISOString() !==
                contractAgreement?.beginAt && {
                beginAt,
              }),
            ...(grossValueInPLN !== contractAgreement?.grossValueInPLN && {
              grossValueInPLN,
            }),
            ...(collectionPeriodEndAt &&
              new Date(collectionPeriodEndAt as Date).toISOString() !==
                contractAgreement?.collectionPeriodEndAt && {
                collectionPeriodEndAt,
              }),
            ...(legalDocumentsVerification !==
              contractAgreement?.legalDocumentsVerification?.toString() && {
              legalDocumentsVerification: !!legalDocumentsVerification,
            }),
            ...(legalAct?.[0]?.id !== contractAgreement?.legalAct && {
              legalAct: legalAct?.length ? legalAct[0]?.id : null,
            }),
            ...(contractor?.[0]?.id !==
              contractAgreement?.contractorAddressId && {
              contractorAddressId: contractor && contractor[0]?.id,
            }),
            deleteFilesIds: selectedFiles.length ? selectedFiles : null,
          },

          files,
          ...(shouldSendAddress && {
            addressInput: {
              name: addressName,
              nip: addressNip && addressNip?.replaceAll("-", "").toString(),
              city: addressCity,
              streetName: addressStreetName,
              buildingNumber: addressBuildingNumber,
              apartmentNumber: addressApartmentNumber,
              mobileNumber: addressMobileNumber,
              postalCode: addressPostalCode,
              countryCode: addressCountryCode && addressCountryCode[0]?.id,
              email: addressEmail ? addressEmail : null,
              phoneNumber: addressPhoneNumber ? addressPhoneNumber : null,
              overwrite: !!addressOverwrite,
              isSensitive: !!addressIsSensitive,
            },
          }),
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.goBack();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { refetch: revealAddress, loading: revealAddressLoading } = useQuery(
    ADDRESS_SUGGESTION,
    {
      skip: true,
    }
  );

  const onAddressRevealSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await revealAddress({
        id: watchAddress && watchAddress[0] && watchAddress[0].id,
      });

      const choosedAddress = response?.data?.addressSuggestion;
      if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
      if (choosedAddress?.isSensitive)
        setValue("addressIsSensitive", choosedAddress?.isSensitive);

      if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
      if (choosedAddress?.streetName)
        setValue("addressStreetName", choosedAddress?.streetName);
      if (choosedAddress?.buildingNumber)
        setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
      if (choosedAddress?.apartmentNumber)
        setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
      if (choosedAddress?.mobileNumber)
        setValue("addressMobileNumber", choosedAddress?.mobileNumber);
      if (choosedAddress?.city) setValue("addressCity", choosedAddress?.city);
      if (choosedAddress?.postalCode)
        setValue("addressPostalCode", choosedAddress?.postalCode);
      if (choosedAddress?.countryCode)
        setValue("addressCountryCode", [
          {
            id: choosedAddress?.countryCode,
          },
        ]);
      if (choosedAddress?.phoneNumber)
        setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
      if (choosedAddress?.email)
        setValue("addressEmail", choosedAddress?.email);

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      setIsAnyAddressRevealed(true);
      setIsAddressRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onFilesRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealFiles({
        variables: {
          documentId,
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      contractAgreementFilesRefetch();
      setIsFilesRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsFilesRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    watchEndAtDate &&
      setValue(
        "collectionPeriodEndAt",
        new Date(+watchEndAtDate + 14 * 86400000)
      );
  }, [watchEndAtDate]);

  useEffect(() => {
    if (errors) scrollOnError(errors, CONTRACT_AGREEMENT_FIELDS, "edit");
  }, [errorTimeStamp]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (contractAgreementFilesError?.graphQLErrors)
      enqueueSnackbar({
        message: (contractAgreementFilesError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [contractAgreementFilesError]);

  useEffect(() => {
    setAddressFromGus(undefined);

    if (!watchAddress?.length) {
      setHasPersonalData(false);
      setIsAnyAddressRevealed(false);
      unregister("addressStreetName");
      unregister("addressBuildingNumber");
      unregister("addressApartmentNumber");
      unregister("addressMobileNumber");
      unregister("addressPostalCode");
      unregister("addressCity");
      unregister("addressCountryCode");
    }
    async function getAddressData() {
      setValue("addressName", "");
      setValue("addressNip", "");
      setValue("addressStreetName", "");
      setValue("addressBuildingNumber", "");
      setValue("addressApartmentNumber", "");
      setValue("addressMobileNumber", "");
      setValue("addressCity", "");
      setValue("addressPostalCode", "");
      setValue("addressPhoneNumber", "");
      setValue("addressEmail", "");
      setValue("addressIsSensitive", false);

      if (watchAddress && watchAddress[0] && watchAddress[0].id) {
        const response = await fetchAddress({
          filter: {
            id: {
              eq: watchAddress[0].id as number,
            },
          },
        });
        const choosedAddress = response?.data?.addressSuggestions?.nodes[0];
        if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
        if (choosedAddress?.__typename === "HiddenAddress") {
          setValue("addressIsSensitive", true);
          setValue("addressCountryCode", []);
          setHasPersonalData(true);
        }
        if (choosedAddress?.__typename === "Address") {
          setHasPersonalData(false);
          setValue("addressIsSensitive", choosedAddress?.isSensitive);
        }

        if (
          (hasPersonalData && isAnyAddressRevealed) ||
          !hasPersonalData ||
          watchAddress[0]?.typename !== "HiddenAddress"
        ) {
          if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
          if (choosedAddress?.streetName)
            setValue("addressStreetName", choosedAddress?.streetName);
          if (choosedAddress?.buildingNumber)
            setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
          if (choosedAddress?.apartmentNumber)
            setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
          if (choosedAddress?.mobileNumber)
            setValue("addressMobileNumber", choosedAddress?.mobileNumber);
          if (choosedAddress?.city)
            setValue("addressCity", choosedAddress?.city);
          if (choosedAddress?.postalCode)
            setValue("addressPostalCode", choosedAddress?.postalCode);
          if (choosedAddress?.countryCode)
            setValue("addressCountryCode", [
              {
                id: choosedAddress?.countryCode,
              },
            ]);
          if (choosedAddress?.phoneNumber)
            setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
          if (choosedAddress?.email)
            setValue("addressEmail", choosedAddress?.email);
          if (choosedAddress?.isSensitive)
            setValue("addressEmail", choosedAddress?.isSensitive);
        }

        setDefaultAddress({
          addressName: choosedAddress?.name || "",
          addressNip: choosedAddress?.nip || "",
          addressStreetName: choosedAddress?.streetName || "",
          addressBuildingNumber: choosedAddress?.buildingNumber || "",
          addressApartmentNumber: choosedAddress?.apartmentNumber || "",
          addressMobileNumber: choosedAddress?.mobileNumber || "",
          addressCity: choosedAddress?.city || "",
          addressCountryCode: choosedAddress?.countryCode
            ? [
                {
                  id: choosedAddress?.countryCode,
                },
              ]
            : [],
          addressPostalCode: choosedAddress?.postalCode || "",
          addressPhoneNumber: choosedAddress?.phoneNumber || "",
          addressEmail: choosedAddress?.email || "",
          addressIsSensitive: choosedAddress?.__typename === HIDDEN_ADDRESS,
        });
        unregister("addressName");
        unregister("addressStreetName");
        unregister("addressBuildingNumber");
        unregister("addressApartmentNumber");
        unregister("addressMobileNumber");
        unregister("addressPostalCode");
        unregister("addressCity");
        unregister("addressCountryCode");
      }
    }

    !addressFromGus && getAddressData();
  }, [watchAddress]);

  useEffect(() => {
    setIsFetching(true);
    setValue("addressCountryCode", [{ id: "PL", label: "Polska (PL)" }]);
  }, []);

  useEffect(() => {
    if (data?.contractAgreement?.currentAssignment) {
      setAssignment(data?.contractAgreement?.currentAssignment);
      setIsAssignmentActive(true);
    }
    if (data?.contractAgreement) {
      setIsFetching(false);
      setValue("contractType", [{ id: data?.contractAgreement?.contractType }]);
      setValue("documentNumber", data?.contractAgreement?.documentNumber);
      data?.contractAgreement?.documentDate &&
        setValue(
          "documentDate",
          new Date(data?.contractAgreement?.documentDate)
        );
      data?.contractAgreement?.beginAt &&
        setValue("beginAt", new Date(data?.contractAgreement?.beginAt));
      data?.contractAgreement?.endAt &&
        setValue("endAt", new Date(data?.contractAgreement?.endAt));
      data?.contractAgreement?.collectionPeriodEndAt &&
        setValue(
          "collectionPeriodEndAt",
          new Date(data?.contractAgreement?.collectionPeriodEndAt)
        );
      setValue("status", [{ id: data?.contractAgreement?.status }]);
      setValue("budgetCategory", data?.contractAgreement?.budgetCategory);
      setValue("grossValueInPLN", data?.contractAgreement?.grossValueInPLN);
      setValue("sequenceNumber", data?.contractAgreement?.sequenceNumber);
      setValue("description", data?.contractAgreement?.description);
      if (data?.contractAgreement?.case)
        setValue("caseName", [
          {
            id: data?.contractAgreement?.case?.id,
            label: `[${data?.contractAgreement?.case?.number}] ${data?.contractAgreement?.case?.name}`,
            ...data?.contractAgreement?.case,
          },
        ]);
      data?.contractAgreement?.organizationalUnit &&
        setValue("organizationalUnit", [
          {
            id: data?.contractAgreement?.organizationalUnit?.id,
            ...data?.contractAgreement?.organizationalUnit,
          },
        ]);
      data?.contractAgreement?.contractorAddress &&
        setValue("contractor", [
          {
            id: data?.contractAgreement?.contractorAddress?.id,
            label: `${
              data?.contractAgreement?.contractorAddress?.name
                ? data?.contractAgreement?.contractorAddress?.name + ", "
                : ""
            } ${
              data?.contractAgreement?.contractorAddress?.address
                ? data?.contractAgreement?.contractorAddress?.address + ", "
                : ""
            } ${
              data?.contractAgreement?.contractorAddress?.city
                ? data?.contractAgreement?.contractorAddress?.city
                : ""
            }`,
            typename: data?.contractAgreement?.contractorAddress?.__typename,
          },
        ]);
      setValue(
        "addressIsSensitive",
        data?.contractAgreement?.contractorAddress?.isSensitive
      );
      setHasPersonalData(
        data?.contractAgreement?.contractorAddress?.__typename ===
          "HiddenAddress" ||
          data?.contractAgreement?.contractorAddress?.isSensitive
      );
      setValue(
        "legalDocumentsVerification",
        data?.contractAgreement?.legalDocumentsVerification
      );
      data?.contractAgreement?.legalAct &&
        setValue("legalAct", [{ id: data?.contractAgreement?.legalAct }]);

      if (data?.contractAgreement?.documentLinks?.length)
        setValue(
          "parentIds",
          data?.contractAgreement?.documentLinks
            ?.filter(
              ({ type, linkerId }: DocumentLink) =>
                type === "Parent" &&
                parseInt(data.contractAgreement.id) === linkerId
            )
            .map(({ linked, linker }: DocumentLink) =>
              parseInt(data.contractAgreement.id) === linker.id
                ? linked
                : linker
            )
        );
    }
  }, [data]);

  useEffect(() => {
    if (addressFromGus) setValue("contractor", undefined);
    if (addressFromGus?.nip) setValue("addressNip", addressFromGus?.nip);
    if (addressFromGus?.name) setValue("addressName", addressFromGus?.name);
    if (addressFromGus?.streetName)
      setValue("addressStreetName", addressFromGus?.streetName);
    if (addressFromGus?.buildingNumber)
      setValue("addressBuildingNumber", addressFromGus?.buildingNumber);
    if (addressFromGus?.apartmentNumber)
      setValue("addressApartmentNumber", addressFromGus?.apartmentNumber);
    if (addressFromGus?.mobileNumber)
      setValue("addressMobileNumber", addressFromGus?.mobileNumber);
    if (addressFromGus?.postalCode)
      setValue("addressPostalCode", addressFromGus?.postalCode);
    if (addressFromGus?.city) setValue("addressCity", addressFromGus?.city);
    if (addressFromGus?.countryCode)
      setValue("addressCountryCode", [{ id: addressFromGus?.countryCode }]);
    if (addressFromGus?.phoneNumber)
      setValue("addressPhoneNumber", addressFromGus?.phoneNumber);
    if (addressFromGus?.email) setValue("addressEmail", addressFromGus?.email);
  }, [addressFromGus]);

  const shouldDisableField = (fieldName: string): boolean =>
    !!data?.contractAgreement?.currentStatus?.documentFlowStep &&
    !data?.contractAgreement?.currentStatus?.documentFlowStep?.allowedDocumentFields?.includes(
      fieldName
    ) &&
    data?.contractAgreement?.currentStatus?.documentFlowStep
      ?.isAllowedToEditAllFields;

  if (
    data &&
    data?.contractAgreement &&
    (!checkPermission(PERMISSIONS.contractAgreement.update) ||
      !data?.contractAgreement?.isEditable)
  )
    <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.contractAgreement?.internalNumber}
        labels={["Dokumenty", "Edytowanie"]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "editContractAgreement",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={4}>
            <FormControl label="Numer" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>
                  {data?.contractAgreement?.internalNumber}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={8}>
            <FormControl label="Data rejestracji" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType="datetime">
                  {data?.contractAgreement?.createdAt}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form id="editContractAgreement" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {CONTRACT_AGREEMENT_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit?.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                      height="20px"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <LabelMedium>
                          {!!data?.contractAgreement?.files?.length &&
                          group.id === "files"
                            ? `${group.label} (${data?.contractAgreement?.files?.length})`
                            : group.label}
                        </LabelMedium>
                        {hasPersonalData &&
                          !isAnyAddressRevealed &&
                          group.id === "contractor" && (
                            <Button
                              size={SIZE.mini}
                              kind={KIND.secondary}
                              $style={{ marginLeft: "10px" }}
                              onClick={() =>
                                setIsAddressRevealConfirmDialogOpen(true)
                              }
                              isLoading={revealAddressLoading || isFetching}
                              type="button"
                            >
                              Pokaż dane
                            </Button>
                          )}
                        {hasHiddenFiles && group.id === "files" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setIsFilesRevealConfirmDialogOpen(true)
                            }
                            isLoading={revealFilesLoading || isFetching}
                            type="button"
                          >
                            Pokaż pliki
                          </Button>
                        )}
                      </Block>
                      {hasPersonalData &&
                        !isAnyAddressRevealed &&
                        group.id === "contractor" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "12px" }}
                          >
                            Dane osobowe - podgląd zablokowany
                          </FormattedValue>
                        )}
                      {!hasHiddenFiles &&
                        !!data?.contractAgreement?.files?.length &&
                        group.id === "files" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "13px" }}
                          >
                            Dostęp jest aktywny do końca trwania sesji
                          </FormattedValue>
                        )}
                    </Block>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </>
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit?.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={
                        (item.id === "filesList" && hasHiddenFiles) ||
                        !data?.contractAgreement?.files?.length
                          ? ""
                          : item.caption
                      }
                      required={item.edit?.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (mutationError &&
                          mutationError.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          mutationError.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading || shouldDisableField(item.id)}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="36px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.AddressesSelect ? (
                        <ControlledAddressesSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.CasesSelect ? (
                        <ControlledCasesSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.ContractStatusSelect ? (
                        <ControlledContractStatusSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.ContractTypeSelect ? (
                        <ControlledContractTypeSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.DocumentsSelect ? (
                        <ControlledDocumentsSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          filterOptions={(options) =>
                            options.filter((o) => o.id != id)
                          }
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                        <ControlledOrganizationalUnitsSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.DictionaryValuesSelect ? (
                        <ControlledDictionaryValuesSelect
                          dictionarySystemName={
                            item.id === "legalAct"
                              ? "legal.basis"
                              : item.id === "addressCountryCode"
                              ? "countryCodes.alpha2.pl"
                              : undefined
                          }
                          control={control}
                          name={item.id}
                          id={item.id}
                          disabled={
                            (hasPersonalData && !isAnyAddressRevealed) ||
                            shouldDisableField(item.id)
                          }
                          placeholder={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.id.includes("address")
                              ? "**********"
                              : "Wybierz"
                          }
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DatePicker ? (
                        <ControlledDatePicker
                          name={item.id}
                          control={control}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.id === "endAt" && {
                            minDate: watchBeginAtDate as Date,
                          })}
                          {...(item.id === "beginAt" && {
                            maxDate: watchEndAtDate as Date,
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <div className={css({ marginTop: "40px" })}>
                          {item.id === "addressOverwrite" ? (
                            <Tooltip
                              placement="bottomLeft"
                              content={
                                <p>
                                  Zaznaczenie tej opcji spowoduje aktualizację
                                  danych <br /> wybranego adresata w bazie, ale
                                  nie wpłynie to na <br /> wcześniejsze
                                  dokumenty powiązane z tym adresatem.
                                </p>
                              }
                            >
                              <span>
                                <ControlledCheckbox
                                  control={control}
                                  name={item.id}
                                  disabled={
                                    shouldDisableField(item.id) ||
                                    loading ||
                                    (hasPersonalData &&
                                      !isAnyAddressRevealed &&
                                      item.id.includes("address") &&
                                      !item.id.includes("Name"))
                                  }
                                  {...(item.edit.required && {
                                    rules: {
                                      required:
                                        formValidation.messages.required,
                                    },
                                  })}
                                >
                                  {item.label}
                                </ControlledCheckbox>
                              </span>
                            </Tooltip>
                          ) : (
                            <ControlledCheckbox
                              control={control}
                              name={item.id}
                              disabled={
                                shouldDisableField(item.id) ||
                                loading ||
                                (hasPersonalData &&
                                  !isAnyAddressRevealed &&
                                  item.id.includes("address") &&
                                  !item.id.includes("Name"))
                              }
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            >
                              {item.label}
                            </ControlledCheckbox>
                          )}
                        </div>
                      ) : item.type === FIELDS.FilesPicker ? (
                        <ControlledFilesPicker
                          control={control}
                          name={item.id}
                          maxSize={134217728}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.FilesList ? (
                        <FilesList
                          files={
                            contractAgreementFilesData?.contractAgreement?.files
                          }
                          hasHiddenFiles={hasHiddenFiles}
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.additionalData === "sensitive"
                              ? "**********"
                              : item.placeholder
                          }
                          rules={{
                            required: item.edit.required,
                            ...(item.id === "addressEmail" && {
                              pattern: {
                                value: formValidation.patterns.email,
                                message: formValidation.messages.incorrectEmail,
                              },
                            }),
                          }}
                          disabled={
                            (hasPersonalData &&
                              !isAnyAddressRevealed &&
                              item.id.includes("address")) ||
                            (item.id === "sequenceNumber" &&
                              !checkPermission(
                                PERMISSIONS.document.updateSequenceNumber
                              ))
                          }
                          masked={
                            item.id === "addressNip"
                              ? watchCountryCode?.[0]?.id === "PL"
                                ? item.mask !== undefined
                                : false
                              : item.mask !== undefined
                          }
                          mask={item.mask}
                          maskChar="_"
                          endEnhancer={
                            item.id === "addressNip" ? (
                              <Tooltip content="Pobierz z GUS">
                                <span
                                  onClick={() => setIsGusDownloadOpen(true)}
                                  className={css({ cursor: "pointer" })}
                                >
                                  <Download
                                    size={16}
                                    className={css({
                                      verticalAlign: "middle",
                                      marginLeft: "6px",
                                      display: "inline",
                                    })}
                                  />
                                </span>
                              </Tooltip>
                            ) : (
                              item.endEnhancer
                            )
                          }
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji umowy"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isAddressRevealConfirmDialogOpen}
              type={ConfirmDialogType.AddressReveal}
              close={() => setIsAddressRevealConfirmDialogOpen(false)}
              confirm={() => onAddressRevealSubmit()}
            />
            <ConfirmDialog
              isOpen={isFilesRevealConfirmDialogOpen}
              type={ConfirmDialogType.FilesReveal}
              close={() => setIsFilesRevealConfirmDialogOpen(false)}
              confirm={() => onFilesRevealSubmit()}
            />
            <GusDownloadModal
              isOpen={isGusDownloadOpen}
              close={() => setIsGusDownloadOpen(false)}
              confirm={() => null}
              currentNip={watchNip}
              setAddressFromGus={setAddressFromGus}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
