import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  AlertOctagon,
  DeviceFloppy,
  Download,
  FileOff,
} from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import FilesList from "../../../../components/files-list";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import GusDownloadModal from "../../../../components/gus-download-modal";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import { ControlledRadioCheckbox } from "../../../../components/radio-checkbox";
import {
  ControlledAddressesSelect,
  ControlledCasesSelect,
  ControlledDictionaryValuesSelect,
  ControlledDocumentsSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledOrganizationalUnitUsersSelect,
  ControlledUsersOrganizationalUnitsSelect,
  ControlledUsersSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import Tooltip from "../../../../components/tooltip";
import { HIDDEN_ADDRESS, HIDDEN_FILE } from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { Address, DefaultAddress } from "../../../Addresses/addresses";
import {
  ADDRESS_SUGGESTION,
  ADDRESS_SUGGESTIONS,
} from "../../../Addresses/addresses.gql";
import { AssignmentLocationState } from "../../../Folders/folders";
import { DocumentLink } from "../../documents";
import { DocumentInputs, DOCUMENTS_FIELDS } from "../../documents.form";
import {
  DOCUMENT_FILES_SHOW,
  DOCUMENTS_SHOW,
  DOCUMENTS_UPDATE,
  FILES_REVEAL,
} from "../../documents.gql";

export default function DocumentsEditIncoming(): React.ReactElement {
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [
    isFilesRevealConfirmDialogOpen,
    setIsFilesRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(false);
  const [hasPersonalData, setHasPersonalData] = useState<boolean>();
  const { user } = useAuth();
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([]);
  const [defaultAddress, setDefaultAddress] = useState<DefaultAddress>();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [addressFromGus, setAddressFromGus] = useState<Address>();
  const [isGusDownloadOpen, setIsGusDownloadOpen] = useState(false);
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading, isFetching, setIsFetching } = useLoading();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);
  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    register,
    unregister,
    watch,
  } = useForm<DocumentInputs>();

  const watchSenderWhenAddress = watch("senderWhenAddress");
  const watchRecipientWhenUser = watch("recipientWhenUser");
  const watchOrganizationalUnit = watch("organizationalUnit");
  const watchHandDelivered = watch("handDelivered");
  const watchIsElectronical = watch("isElectronical");
  const watchCountryCode = watch("addressCountryCode");
  const watchNip = watch("addressNip");

  const isSensitiveAddressChoosed =
    watchSenderWhenAddress &&
    watchSenderWhenAddress?.[0]?.typename === HIDDEN_ADDRESS;

  const { data, error: queryError } = useQuery(DOCUMENTS_SHOW, {
    variables: { id: documentId },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const {
    data: documentFilesData,
    refetch: documentFilesRefetch,
    error: documentFilesError,
  } = useQuery(DOCUMENT_FILES_SHOW, {
    variables: { id: documentId },
    fetchPolicy: "no-cache",
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const hasHiddenFiles =
    !!documentFilesData?.document?.files?.length &&
    documentFilesData?.document?.files[0]?.__typename === HIDDEN_FILE;

  const { refetch: fetchAddress } = useQuery(ADDRESS_SUGGESTIONS, {
    skip: true,
  });

  const [updateDocument, { error: mutationError, loading }] = useMutation(
    DOCUMENTS_UPDATE,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    description,
    title,
    programName,
    parentIds,
    senderWhenAddress,
    caseName,
    organizationalUnit,
    documentNumber,
    documentDate,
    sequenceNumber,
    inflowDate,
    postStampAt,
    handDelivered,
    files,
    addressName,
    addressNip,
    addressCity,
    addressStreetName,
    addressBuildingNumber,
    addressApartmentNumber,
    addressMobileNumber,
    addressPostalCode,
    addressCountryCode,
    addressEmail,
    addressPhoneNumber,
    addressOverwrite,
    addressIsSensitive,
    isFullyDigitallyReproduced,
    isElectronical,
    name,
    additionalCode,
    raksDiaryNumber,
  }: DocumentInputs): Promise<void> => {
    setIsLoading(true);

    const enteredAddress = {
      addressName,
      addressNip,
      addressStreetName,
      addressBuildingNumber,
      addressApartmentNumber,
      addressMobileNumber,
      addressCity,
      addressCountryCode,
      addressPostalCode,
      addressPhoneNumber,
      addressEmail,
      addressIsSensitive,
    };

    const shouldSendAddress =
      JSON.stringify(enteredAddress) !== JSON.stringify(defaultAddress);

    const { document } = data;

    try {
      await updateDocument({
        variables: {
          documentUpdateInput: {
            id: documentId,
            userId: user?.id,
            parentIds: parentIds?.length ? parentIds.map(({ id }) => id) : null,
            ...(documentNumber !== document?.documentNumber && {
              documentNumber,
            }),
            ...(documentDate &&
              new Date(documentDate as Date).toISOString() !==
                document?.documentDate && {
                documentDate,
              }),
            ...(inflowDate &&
              new Date(inflowDate as Date).toISOString() !==
                document?.inflowDate && {
                inflowDate,
              }),
            ...(description !== document?.description && {
              description,
            }),
            ...(title !== document?.title && {
              title,
            }),
            ...(sequenceNumber !== document?.sequenceNumber && {
              sequenceNumber,
            }),
            ...(programName !== document?.programName && {
              programName,
            }),
            ...(name !== document?.name && {
              name,
            }),
            ...(additionalCode !== document?.additionalCode && {
              additionalCode,
            }),
            ...(raksDiaryNumber !== document?.raksDiaryNumber && {
              raksDiaryNumber,
            }),
            ...((postStampAt || (!postStampAt && watchHandDelivered)) &&
              new Date(postStampAt as Date).toISOString() !==
                document?.postStampAt && {
                postStampAt: !watchHandDelivered ? postStampAt : null,
              }),
            ...(handDelivered !== document?.handDelivered && {
              handDelivered,
            }),
            ...(senderWhenAddress?.[0]?.id !== document?.senderId && {
              senderId: senderWhenAddress && senderWhenAddress[0]?.id,
            }),
            ...(caseName?.[0]?.id !== document?.case?.id && {
              caseId: caseName?.length ? caseName[0]?.id : null,
            }),
            ...(organizationalUnit?.[0]?.id !==
              document?.organizationalUnit?.id && {
              organizationalUnitId:
                organizationalUnit && organizationalUnit[0]?.id,
            }),
            deleteFilesIds: selectedFiles.length ? selectedFiles : null,
            ...(document?.isFullyDigitallyReproduced?.toString() !==
              isFullyDigitallyReproduced && {
              isFullyDigitallyReproduced: !!isFullyDigitallyReproduced,
            }),
            ...(document?.isElectronical?.toString() !== isElectronical && {
              isElectronical: !!isElectronical,
            }),
          },
          files,
          ...(shouldSendAddress && {
            addressInput: {
              name: addressName,
              nip: addressNip && addressNip?.replaceAll("-", "").toString(),
              city: addressCity,
              streetName: addressStreetName,
              buildingNumber: addressBuildingNumber,
              apartmentNumber: addressApartmentNumber,
              mobileNumber: addressMobileNumber,
              postalCode: addressPostalCode,
              countryCode: addressCountryCode && addressCountryCode[0]?.id,
              email: addressEmail ? addressEmail : null,
              phoneNumber: addressPhoneNumber ? addressPhoneNumber : null,
              overwrite: !!addressOverwrite,
              isSensitive: !!addressIsSensitive,
            },
          }),
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.goBack();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { refetch: revealAddress, loading: revealAddressLoading } = useQuery(
    ADDRESS_SUGGESTION,
    {
      skip: true,
    }
  );

  const onAddressRevealSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await revealAddress({
        id:
          watchSenderWhenAddress &&
          watchSenderWhenAddress[0] &&
          watchSenderWhenAddress[0].id,
      });

      const choosedAddress = response?.data?.addressSuggestion;
      if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
      if (choosedAddress?.isSensitive)
        setValue("addressIsSensitive", choosedAddress?.isSensitive);
      if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
      if (choosedAddress?.streetName)
        setValue("addressStreetName", choosedAddress?.streetName);
      if (choosedAddress?.buildingNumber)
        setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
      if (choosedAddress?.apartmentNumber)
        setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
      if (choosedAddress?.mobileNumber)
        setValue("addressMobileNumber", choosedAddress?.mobileNumber);
      if (choosedAddress?.city) setValue("addressCity", choosedAddress?.city);
      if (choosedAddress?.postalCode)
        setValue("addressPostalCode", choosedAddress?.postalCode);
      if (choosedAddress?.countryCode)
        setValue("addressCountryCode", [
          {
            id: choosedAddress?.countryCode,
          },
        ]);
      if (choosedAddress?.phoneNumber)
        setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
      if (choosedAddress?.email)
        setValue("addressEmail", choosedAddress?.email);

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      setIsAnyAddressRevealed(true);
      setIsAddressRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onFilesRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealFiles({
        variables: {
          documentId,
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      documentFilesRefetch();
      setIsFilesRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsFilesRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (documentFilesError?.graphQLErrors)
      enqueueSnackbar({
        message: (documentFilesError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [documentFilesError]);

  useEffect(() => {
    setAddressFromGus(undefined);

    if (!watchSenderWhenAddress?.length) {
      setHasPersonalData(false);
      setIsAnyAddressRevealed(false);
      unregister("addressStreetName");
      unregister("addressBuildingNumber");
      unregister("addressApartmentNumber");
      unregister("addressMobileNumber");
      unregister("addressPostalCode");
      unregister("addressCity");
      unregister("addressCountryCode");
    }

    async function getAddressData() {
      setValue("addressName", "");
      setValue("addressNip", "");
      setValue("addressStreetName", "");
      setValue("addressBuildingNumber", "");
      setValue("addressApartmentNumber", "");
      setValue("addressMobileNumber", "");
      setValue("addressCity", "");
      setValue("addressPostalCode", "");
      setValue("addressPhoneNumber", "");
      setValue("addressEmail", "");
      setValue("addressIsSensitive", false);

      if (
        watchSenderWhenAddress &&
        watchSenderWhenAddress[0] &&
        watchSenderWhenAddress[0].id
      ) {
        const response = await fetchAddress({
          filter: {
            id: {
              eq: watchSenderWhenAddress[0].id as number,
            },
          },
        });
        const choosedAddress = response?.data?.addressSuggestions?.nodes[0];
        if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
        if (choosedAddress?.__typename === "HiddenAddress") {
          setValue("addressIsSensitive", true);
          setValue("addressCountryCode", []);
          setHasPersonalData(true);
        }
        if (choosedAddress?.__typename === "Address") {
          setHasPersonalData(false);
          setValue("addressIsSensitive", choosedAddress?.isSensitive);
        }

        if (
          (hasPersonalData && isAnyAddressRevealed) ||
          !hasPersonalData ||
          watchSenderWhenAddress[0]?.typename !== "HiddenAddress"
        ) {
          if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
          if (choosedAddress?.streetName)
            setValue("addressStreetName", choosedAddress?.streetName);
          if (choosedAddress?.buildingNumber)
            setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
          if (choosedAddress?.apartmentNumber)
            setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
          if (choosedAddress?.mobileNumber)
            setValue("addressMobileNumber", choosedAddress?.mobileNumber);
          if (choosedAddress?.city)
            setValue("addressCity", choosedAddress?.city);
          if (choosedAddress?.postalCode)
            setValue("addressPostalCode", choosedAddress?.postalCode);
          if (choosedAddress?.countryCode)
            setValue("addressCountryCode", [
              {
                id: choosedAddress?.countryCode,
              },
            ]);
          if (choosedAddress?.phoneNumber)
            setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
          if (choosedAddress?.email)
            setValue("addressEmail", choosedAddress?.email);
          if (choosedAddress?.isSensitive)
            setValue("addressEmail", choosedAddress?.isSensitive);
        }

        setDefaultAddress({
          addressName: choosedAddress?.name || "",
          addressNip: choosedAddress?.nip || "",
          addressStreetName: choosedAddress?.streetName || "",
          addressBuildingNumber: choosedAddress?.buildingNumber || "",
          addressApartmentNumber: choosedAddress?.apartmentNumber || "",
          addressMobileNumber: choosedAddress?.mobileNumber || "",
          addressCity: choosedAddress?.city || "",
          addressCountryCode: choosedAddress?.countryCode
            ? [
                {
                  id: choosedAddress?.countryCode,
                },
              ]
            : [],
          addressPostalCode: choosedAddress?.postalCode || "",
          addressPhoneNumber: choosedAddress?.phoneNumber || "",
          addressEmail: choosedAddress?.email || "",
          addressIsSensitive: choosedAddress?.__typename === HIDDEN_ADDRESS,
        });
        unregister("addressName");
        unregister("addressStreetName");
        unregister("addressBuildingNumber");
        unregister("addressApartmentNumber");
        unregister("addressMobileNumber");
        unregister("addressPostalCode");
        unregister("addressCity");
        unregister("addressCountryCode");
      }
    }

    !addressFromGus && getAddressData();
  }, [watchSenderWhenAddress]);

  useEffect(() => {
    if (watchIsElectronical === "true") {
      unregister("addressStreetName");
      unregister("addressBuildingNumber");
      unregister("addressApartmentNumber");
      unregister("addressMobileNumber");
      unregister("addressPostalCode");
      unregister("addressCity");
      unregister("addressCountryCode");

      register("addressEmail");
    } else if (watchIsElectronical === "false") {
      unregister("addressEmail");

      register("addressStreetName");
      register("addressBuildingNumber");
      register("addressApartmentNumber");
      register("addressMobileNumber");
      register("addressPostalCode");
      register("addressCity");
      register("addressCountryCode");
    }
  }, [watchIsElectronical]);

  useEffect(() => {
    if (data?.document?.currentAssignment) {
      setAssignment(data?.document?.currentAssignment);
      setIsAssignmentActive(true);
    }
    if (data?.document) {
      setIsFetching(false);
      setValue("documentNumber", data?.document?.documentNumber);
      setValue("name", data?.document?.name);
      !!data?.document?.documentDate &&
        setValue("documentDate", new Date(data?.document?.documentDate));
      !!data?.document?.inflowDate &&
        setValue("inflowDate", new Date(data?.document?.inflowDate));
      setValue("description", data?.document?.description);
      setValue("title", data?.document?.title);
      setValue("programName", data?.document?.programName);
      setValue("raksDiaryNumber", data?.document?.raksDiaryNumber);
      setValue("handDelivered", data?.document?.handDelivered);
      setValue(
        "isFullyDigitallyReproduced",
        data?.document?.isFullyDigitallyReproduced ? "true" : "false"
      );
      setValue(
        "isElectronical",
        data?.document?.isElectronical ? "true" : "false"
      );
      if (data?.document?.case)
        setValue("caseName", [
          {
            id: data?.document?.case?.id,
            label: `[${data?.document?.case?.number}] ${data?.document?.case?.name}`,
            ...data?.document?.case,
          },
        ]);
      if (data?.document?.documentLinks?.length)
        setValue(
          "parentIds",
          data?.document?.documentLinks
            ?.filter(
              ({ type, linkerId }: DocumentLink) =>
                type === "Parent" && parseInt(id as string) === linkerId
            )
            .map(({ linked, linker }: DocumentLink) =>
              parseInt(id as string) === linker.id ? linked : linker
            )
        );
    }
    !!data?.document?.postStampAt &&
      setValue("postStampAt", new Date(data?.document?.postStampAt));

    setValue("senderWhenAddress", [
      {
        id: data?.document?.sender?.id,
        label: `${
          data?.document?.sender?.name
            ? data?.document?.sender?.name + ", "
            : ""
        } ${
          data?.document?.sender?.address
            ? data?.document?.sender?.address + ", "
            : ""
        } ${data?.document?.sender?.city ? data?.document?.sender?.city : ""}`,
        typename: data?.document?.sender?.__typename,
      },
    ]);

    setValue("addressIsSensitive", data?.document?.sender?.isSensitive);
    setValue("sequenceNumber", data?.document?.sequenceNumber);
    if (data?.document?.organizationalUnit?.id)
      setValue("organizationalUnit", [
        {
          id: data?.document?.organizationalUnit?.id,
          label: `[
        ${data?.document?.organizationalUnit?.symbol}
      ] ${data?.document?.organizationalUnit?.name}`,
        },
      ]);
    if (data?.document?.recipient)
      setValue("recipientWhenUser", [
        {
          id: data?.document?.recipient?.id,
          label: `${data?.document?.recipient?.firstName} ${data?.document?.recipient?.lastName}`,
        },
      ]);

    if (data?.document?.documentType)
      setValue("documentType", [
        {
          id: data?.document?.documentType,
          label: data?.document?.documentType,
        },
      ]);
    setHasPersonalData(
      data?.document?.sender?.__typename === "HiddenAddress" ||
        data?.document?.sender?.isSensitive
    );
  }, [data]);

  useEffect(() => {
    if (watchHandDelivered) setValue("postStampAt", null);
  }, [watchHandDelivered]);

  useEffect(() => {
    if (addressFromGus) setValue("senderWhenAddress", undefined);
    if (addressFromGus?.nip) setValue("addressNip", addressFromGus?.nip);
    if (addressFromGus?.name) setValue("addressName", addressFromGus?.name);
    if (addressFromGus?.streetName)
      setValue("addressStreetName", addressFromGus?.streetName);
    if (addressFromGus?.buildingNumber)
      setValue("addressBuildingNumber", addressFromGus?.buildingNumber);
    if (addressFromGus?.apartmentNumber)
      setValue("addressApartmentNumber", addressFromGus?.apartmentNumber);
    if (addressFromGus?.mobileNumber)
      setValue("addressMobileNumber", addressFromGus?.mobileNumber);
    if (addressFromGus?.postalCode)
      setValue("addressPostalCode", addressFromGus?.postalCode);
    if (addressFromGus?.city) setValue("addressCity", addressFromGus?.city);
    if (addressFromGus?.countryCode)
      setValue("addressCountryCode", [{ id: addressFromGus?.countryCode }]);
    if (addressFromGus?.phoneNumber)
      setValue("addressPhoneNumber", addressFromGus?.phoneNumber);
    if (addressFromGus?.email) setValue("addressEmail", addressFromGus?.email);
  }, [addressFromGus]);

  useEffect(() => {
    if (errors) scrollOnError(errors, DOCUMENTS_FIELDS, "edit");
  }, [errorTimeStamp]);

  const shouldDisableField = (fieldName: string): boolean =>
    !!data?.document?.currentStatus?.documentFlowStep &&
    !data?.document?.currentStatus?.documentFlowStep?.allowedDocumentFields?.includes(
      fieldName
    ) &&
    !data?.document?.currentStatus?.documentFlowStep?.isAllowedToEditAllFields;

  if (
    data &&
    data?.document &&
    (!checkPermission(PERMISSIONS.document.update) ||
      !data?.document?.isEditable)
  )
    <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.document?.internalNumber}
        labels={["Dokumenty", "Edytowanie"]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "editIncomingDocument",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={4}>
            <FormControl label="Numer" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>
                  {data?.document?.internalNumber}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={8}>
            <FormControl label="Data rejestracji" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType="datetime">
                  {data?.document?.createdAt}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form id="editIncomingDocument" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {DOCUMENTS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit?.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                      height="20px"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <LabelMedium>
                          {!!data?.document?.files?.length &&
                          group.id === "files"
                            ? `${group.label} (${data?.document?.files?.length})`
                            : group.label}
                        </LabelMedium>
                        {hasPersonalData &&
                          !isAnyAddressRevealed &&
                          group.id === "sender" && (
                            <Button
                              size={SIZE.mini}
                              kind={KIND.secondary}
                              $style={{ marginLeft: "10px" }}
                              onClick={() =>
                                setIsAddressRevealConfirmDialogOpen(true)
                              }
                              isLoading={revealAddressLoading || isFetching}
                              type="button"
                            >
                              Pokaż dane
                            </Button>
                          )}
                        {hasHiddenFiles && group.id === "files" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setIsFilesRevealConfirmDialogOpen(true)
                            }
                            isLoading={revealFilesLoading || isFetching}
                            type="button"
                          >
                            Pokaż pliki
                          </Button>
                        )}
                      </Block>
                      {hasPersonalData &&
                        !isAnyAddressRevealed &&
                        group.id === "sender" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "12px" }}
                          >
                            Dane osobowe - podgląd zablokowany
                          </FormattedValue>
                        )}
                      {!hasHiddenFiles &&
                        !!data?.document?.files?.length &&
                        group.id === "files" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "13px" }}
                          >
                            Dostęp jest aktywny do końca trwania sesji
                          </FormattedValue>
                        )}
                    </Block>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </>
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit?.visible)
                .filter((f) => f.typeName?.includes("Incoming"))
                .filter(
                  (f) =>
                    f.id !== "isFullyDigitallyReproduced" ||
                    (f.id === "isFullyDigitallyReproduced" &&
                      watchIsElectronical === "false")
                )
                .map((item, index) => (
                  <Cell
                    span={item.span || 6}
                    skip={item.skip || 0}
                    key={group.id + `-field` + index}
                  >
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={
                        (item.id === "filesList" && hasHiddenFiles) ||
                        !data?.document?.files?.length
                          ? ""
                          : item.caption
                      }
                      required={
                        (item.info === "notElectronicalRequired" &&
                          watchIsElectronical === "false") ||
                        (item.info === "electronicalRequired" &&
                          watchIsElectronical === "true") ||
                        item.edit.required
                      }
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (mutationError &&
                          mutationError.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          mutationError.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find((vE: InputValidationError) => {
                              const itemId = item.id.includes("address")
                                ? item.id.slice(7).charAt(0).toLowerCase() +
                                  item.id.slice(8)
                                : item.id;

                              return vE?.property === itemId;
                            })
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading || shouldDisableField(item.id)}
                    >
                      <>
                        {isFetching ? (
                          <Skeleton
                            rows={0}
                            height="36px"
                            width="100%"
                            animation
                          />
                        ) : item.type === FIELDS.AddressesSelect ? (
                          <ControlledAddressesSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.UsersSelect ? (
                          <ControlledUsersSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.CasesSelect ? (
                          <ControlledCasesSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                          <ControlledOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.DocumentsSelect ? (
                          <ControlledDocumentsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            filterOptions={(options) =>
                              options.filter((o) => o.id != id)
                            }
                            rules={{
                              ...(item.edit.required && {
                                required: formValidation.messages.required,
                              }),
                              validate: (value) => {
                                return value?.[0]?.id?.toString() === id
                                  ? "Dokument nie może być nadrzędnym sam dla siebie"
                                  : true;
                              },
                            }}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type ===
                          FIELDS.OrganizationalUnitUsersSelect ? (
                          watchOrganizationalUnit &&
                          watchOrganizationalUnit.length > 0 ? (
                            <ControlledOrganizationalUnitUsersSelect
                              objectId={watchOrganizationalUnit[0].id}
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                              disabled={shouldDisableField(item.id)}
                            />
                          ) : (
                            <ControlledUsersSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                              disabled={shouldDisableField(item.id)}
                            />
                          )
                        ) : item.type ===
                          FIELDS.UsersOrganizationalUnitsSelect ? (
                          watchRecipientWhenUser &&
                          watchRecipientWhenUser.length > 0 ? (
                            <ControlledUsersOrganizationalUnitsSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              objectId={watchRecipientWhenUser[0].id}
                              placeholder="Wybierz"
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                              disabled={shouldDisableField(item.id)}
                            />
                          ) : (
                            <ControlledOrganizationalUnitsSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                              disabled={shouldDisableField(item.id)}
                            />
                          )
                        ) : item.type === FIELDS.DictionaryValuesSelect ? (
                          <ControlledDictionaryValuesSelect
                            dictionarySystemName={
                              item.id === "addressCountryCode"
                                ? "countryCodes.alpha2.pl"
                                : item.id === "documentType"
                                ? "documentTypes.incoming"
                                : undefined
                            }
                            control={control}
                            disabled={
                              (hasPersonalData && !isAnyAddressRevealed) ||
                              shouldDisableField(item.id)
                            }
                            name={item.id}
                            id={item.id}
                            placeholder={
                              hasPersonalData &&
                              !isAnyAddressRevealed &&
                              item.additionalData === "sensitive"
                                ? "**********"
                                : "Wybierz"
                            }
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DatePicker ? (
                          <ControlledDatePicker
                            name={item.id}
                            control={control}
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={
                              (item.id === "postStampAt" &&
                                watchHandDelivered) ||
                              shouldDisableField(item.id)
                            }
                            {...(item.id === "postStampAt" && {
                              $style: { paddingRight: "35px" },
                            })}
                          />
                        ) : item.type === FIELDS.FilesList ? (
                          data?.document?.files && (
                            <FilesList
                              files={documentFilesData?.document?.files}
                              hasHiddenFiles={hasHiddenFiles}
                              selectedFiles={selectedFiles}
                              setSelectedFiles={setSelectedFiles}
                              disabled={shouldDisableField(item.id)}
                            />
                          )
                        ) : item.type === FIELDS.FilesPicker ? (
                          <ControlledFilesPicker
                            control={control}
                            name={item.id}
                            maxSize={134217728}
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.RadioCheckbox ? (
                          <ControlledRadioCheckbox
                            control={control}
                            name={item.id}
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            {...(item.id === "isElectronical" && {
                              trueText: "Elektroniczna",
                              falseText: "Papierowa",
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.Checkbox ? (
                          <div className={css({ marginTop: "40px" })}>
                            {item.id === "addressOverwrite" ? (
                              <Tooltip
                                placement="bottomLeft"
                                content={
                                  <div>
                                    Zaznaczenie tej opcji spowoduje aktualizację
                                    danych <br /> wybranego adresata w bazie,
                                    ale nie wpłynie to na <br /> wcześniejsze
                                    dokumenty powiązane z tym adresatem.
                                  </div>
                                }
                              >
                                <span>
                                  <ControlledCheckbox
                                    control={control}
                                    name={item.id}
                                    disabled={
                                      shouldDisableField(item.id) ||
                                      loading ||
                                      (hasPersonalData &&
                                        !isAnyAddressRevealed &&
                                        item.id.includes("address") &&
                                        !item.id.includes("Name"))
                                    }
                                    {...(item.edit.required && {
                                      rules: {
                                        required:
                                          formValidation.messages.required,
                                      },
                                    })}
                                  >
                                    {item.label}
                                  </ControlledCheckbox>
                                </span>
                              </Tooltip>
                            ) : (
                              <ControlledCheckbox
                                control={control}
                                name={item.id}
                                disabled={
                                  shouldDisableField(item.id) ||
                                  loading ||
                                  (hasPersonalData &&
                                    !isAnyAddressRevealed &&
                                    item.id.includes("address") &&
                                    !item.id.includes("Name"))
                                }
                                {...(item.edit.required && {
                                  rules: {
                                    required: formValidation.messages.required,
                                  },
                                })}
                              >
                                {item.label}
                              </ControlledCheckbox>
                            )}
                          </div>
                        ) : item.type === FIELDS.TextArea ? (
                          <ControlledTextArea
                            control={control}
                            name={item.id}
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : (
                          <ControlledInput
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={
                              hasPersonalData &&
                              !isAnyAddressRevealed &&
                              item.additionalData === "sensitive"
                                ? "**********"
                                : item.placeholder
                            }
                            rules={{
                              required:
                                (item.info === "notElectronicalRequired" &&
                                  watchIsElectronical === "false" &&
                                  !isSensitiveAddressChoosed) ||
                                (item.info === "electronicalRequired" &&
                                  watchIsElectronical === "true" &&
                                  !isSensitiveAddressChoosed) ||
                                item.edit.required,
                              ...(item.id === "addressEmail" && {
                                pattern: {
                                  value: formValidation.patterns.email,
                                  message:
                                    formValidation.messages.incorrectEmail,
                                },
                              }),
                            }}
                            disabled={
                              (hasPersonalData &&
                                !isAnyAddressRevealed &&
                                item.id.includes("address")) ||
                              (item.id === "sequenceNumber" &&
                                !checkPermission(
                                  PERMISSIONS.document.updateSequenceNumber
                                )) ||
                              shouldDisableField(item.id)
                            }
                            masked={
                              item.id === "addressNip"
                                ? watchCountryCode?.[0]?.id === "PL"
                                  ? item.mask !== undefined
                                  : false
                                : item.mask !== undefined
                            }
                            mask={item.mask}
                            maskChar="_"
                            endEnhancer={
                              item.id === "addressNip" ? (
                                <Tooltip content="Pobierz z GUS">
                                  <span
                                    onClick={() => setIsGusDownloadOpen(true)}
                                    className={css({ cursor: "pointer" })}
                                  >
                                    <Download
                                      size={16}
                                      className={css({
                                        verticalAlign: "middle",
                                        marginLeft: "6px",
                                        display: "inline",
                                      })}
                                    />
                                  </span>
                                </Tooltip>
                              ) : (
                                item.endEnhancer
                              )
                            }
                          />
                        )}
                      </>
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji dokumentu przychodzącego"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isAddressRevealConfirmDialogOpen}
              type={ConfirmDialogType.AddressReveal}
              close={() => setIsAddressRevealConfirmDialogOpen(false)}
              confirm={() => onAddressRevealSubmit()}
            />
            <ConfirmDialog
              isOpen={isFilesRevealConfirmDialogOpen}
              type={ConfirmDialogType.FilesReveal}
              close={() => setIsFilesRevealConfirmDialogOpen(false)}
              confirm={() => onFilesRevealSubmit()}
            />
            <GusDownloadModal
              isOpen={isGusDownloadOpen}
              close={() => setIsGusDownloadOpen(false)}
              confirm={() => null}
              currentNip={watchNip}
              setAddressFromGus={setAddressFromGus}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
