import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import FilesList from "../../../../components/files-list";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import {
  ControlledCasesSelect,
  ControlledContractsSelect,
  ControlledCurrencySelect,
  ControlledUsersOrganizationalUnitsSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import { HIDDEN_FILE } from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { AssignmentLocationState } from "../../../Folders/folders";
import { FILES_REVEAL } from "../../documents.gql";
import {
  BILL_ISSUANCE_REQUEST_FIELDS,
  BillIssuanceRequestInputs,
} from "../bill-issuance-request.form";
import {
  BILL_ISSUANCE_REQUEST_EDIT,
  BILL_ISSUANCE_REQUEST_FILES_SHOW,
  BILL_ISSUANCE_REQUEST_SHOW,
} from "../bill-issuance-request.gql";

export default function DocumentsEditBillIssuanceRequest(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([]);
  const [
    isFilesRevealConfirmDialogOpen,
    setIsFilesRevealConfirmDialogOpen,
  ] = useState(false);
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const { user } = useAuth();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    setValue,
    watch,
  } = useForm<BillIssuanceRequestInputs>();

  const watchCurrency = watch("currencies");

  const { data, error: queryError } = useQuery(BILL_ISSUANCE_REQUEST_SHOW, {
    variables: { id: documentId },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const {
    data: billIssuanceRequestFilesData,
    refetch: billIssuanceRequestFilesRefetch,
    error: billIssuanceRequestFilesError,
  } = useQuery(BILL_ISSUANCE_REQUEST_FILES_SHOW, {
    variables: { id: documentId },
    fetchPolicy: "no-cache",
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const hasHiddenFiles =
    !!billIssuanceRequestFilesData?.billIssuanceRequest?.files?.length &&
    billIssuanceRequestFilesData?.billIssuanceRequest?.files[0]?.__typename ===
      HIDDEN_FILE;

  const [updateBillIssuanceRequest, { error: mutationError }] = useMutation(
    BILL_ISSUANCE_REQUEST_EDIT,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    parentIds,
    contractorName,
    workedHoursCount,
    contractorAccountNumber,
    remarks,
    description,
    sequenceNumber,
    grossValue,
    files,
    organizationalUnits,
    currencies,
  }: BillIssuanceRequestInputs): Promise<void> => {
    setIsLoading(true);

    const { billIssuanceRequest } = data;
    const documentGrossValue = parseFloat(
      billIssuanceRequest?.grossValue?.toString()?.replace(/,/g, ".")
    );
    const formGrossValue = parseFloat(
      grossValue?.toString()?.replace(/,/g, ".")
    );
    const documentWorkedHoursCount = parseFloat(
      billIssuanceRequest?.workedHoursCount?.toString().replace(/,/, ".")
    );
    const formWorkedHoursCount = parseFloat(
      workedHoursCount?.toString().replace(/,/, ".")
    );

    try {
      await updateBillIssuanceRequest({
        variables: {
          billIssuanceRequestUpdateInput: {
            id: documentId,
            userId: user?.id,
            parentIds: parentIds?.length ? parentIds.map(({ id }) => id) : null,
            ...(contractorName !== billIssuanceRequest?.contractorName && {
              contractorName,
            }),
            ...(description !== billIssuanceRequest?.description && {
              description,
            }),
            ...(sequenceNumber !== billIssuanceRequest?.sequenceNumber && {
              sequenceNumber,
            }),
            ...(documentGrossValue !== formGrossValue && {
              grossValue: parseFloat(
                grossValue?.toString()?.replace(/,/g, ".")
              ),
            }),
            ...(organizationalUnits?.[0]?.id !==
              billIssuanceRequest?.organizationalUnit?.id && {
              organizationalUnitId:
                organizationalUnits && organizationalUnits[0]?.id,
            }),
            ...(documentWorkedHoursCount !== formWorkedHoursCount && {
              workedHoursCount: parseFloat(
                workedHoursCount?.toString().replace(/,/, ".")
              ),
            }),
            ...(contractorAccountNumber !==
              billIssuanceRequest?.contractorAccountNumber && {
              contractorAccountNumber,
            }),
            ...(remarks !== billIssuanceRequest?.remarks && {
              remarks,
            }),
            deleteFilesIds: selectedFiles.length ? selectedFiles : null,
            ...(currencies?.[0]?.id !== billIssuanceRequest?.currencyId && {
              currencyId: currencies?.[0]?.currencyId || null,
            }),
          },
          files,
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.goBack();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (billIssuanceRequestFilesError?.graphQLErrors)
      enqueueSnackbar({
        message: (billIssuanceRequestFilesError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [billIssuanceRequestFilesError]);

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onFilesRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealFiles({
        variables: {
          documentId,
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      billIssuanceRequestFilesRefetch();
      setIsFilesRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsFilesRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (data?.billIssuanceRequest?.currentAssignment) {
      setAssignment(data?.billIssuanceRequest?.currentAssignment);
      setIsAssignmentActive(true);
    }

    if (data?.billIssuanceRequest) {
      data?.billIssuanceRequest?.documentLinks?.[0]?.linked &&
        setValue("parentIds", [
          { id: data?.billIssuanceRequest?.documentLinks?.[0]?.linked?.id },
        ]);
      data?.billIssuanceRequest?.organizationalUnit &&
        setValue("organizationalUnits", [
          { id: data?.billIssuanceRequest?.organizationalUnit?.id },
        ]);
      setValue("contractorName", data?.billIssuanceRequest?.contractorName);
      setValue("grossValue", data?.billIssuanceRequest?.grossValue);
      setValue("sequenceNumber", data?.billIssuanceRequest?.sequenceNumber);
      setValue("workedHoursCount", data?.billIssuanceRequest?.workedHoursCount);
      setValue(
        "contractorAccountNumber",
        data?.billIssuanceRequest?.contractorAccountNumber
      );

      data?.billIssuanceRequest?.currency &&
        setValue("currencies", [
          {
            id: data?.billIssuanceRequest?.currency?.code,
          },
        ]);
      setValue("remarks", data?.billIssuanceRequest?.remarks);
      data?.billIssuanceRequest?.case &&
        setValue("cases", [{ id: data?.billIssuanceRequest?.case?.id }]);
      setValue("grossValue", data?.billIssuanceRequest?.grossValue);

      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    if (errors) scrollOnError(errors, BILL_ISSUANCE_REQUEST_FIELDS, "edit");
  }, [errorTimeStamp]);

  const shouldDisableField = (fieldName: string): boolean =>
    !!data?.billIssuanceRequest?.currentStatus?.documentFlowStep &&
    !data?.billIssuanceRequest?.currentStatus?.documentFlowStep?.allowedDocumentFields?.includes(
      fieldName
    ) &&
    data?.billIssuanceRequest?.currentStatus?.documentFlowStep
      ?.isAllowedToEditAllFields;

  if (
    data &&
    data?.billIssuanceRequest &&
    (!checkPermission(PERMISSIONS.billIssuanceRequest.update) ||
      !data?.billIssuanceRequest?.isEditable)
  )
    <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.billIssuanceRequest?.internalNumber}
        labels={["Dokumenty", "Edytowanie"]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              !(Object.keys(dirtyFields).length <= 1)
                ? setIsCancelConfirmDialogOpen(true)
                : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "editBillIssuanceRequest",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={4}>
            <FormControl label="Numer" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>
                  {data?.billIssuanceRequest?.internalNumber}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={8}>
            <FormControl label="Data rejestracji" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType="datetime">
                  {data?.billIssuanceRequest?.createdAt}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form id="editBillIssuanceRequest" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {BILL_ISSUANCE_REQUEST_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit?.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                      height="20px"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <LabelMedium>
                          {!!data?.billIssuanceRequest?.files?.length &&
                          group.id === "files"
                            ? `${group.label} (${data?.billIssuanceRequest?.files?.length})`
                            : group.label}
                        </LabelMedium>
                        {hasHiddenFiles && group.id === "files" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setIsFilesRevealConfirmDialogOpen(true)
                            }
                            isLoading={revealFilesLoading || isFetching}
                            type="button"
                          >
                            Pokaż pliki
                          </Button>
                        )}
                      </Block>
                      {!hasHiddenFiles &&
                        !!data?.billIssuanceRequest?.files?.length &&
                        group.id === "files" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "13px" }}
                          >
                            Dostęp jest aktywny do końca trwania sesji
                          </FormattedValue>
                        )}
                    </Block>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </>
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit?.visible)
                .map((item, index) => (
                  <Cell
                    span={item.span || 6}
                    skip={item.skip || 0}
                    key={group.id + `-field` + index}
                  >
                    {item.id === "remarks" ? (
                      <LabelMedium
                        $style={{ marginBottom: "20px", marginTop: "10px" }}
                      >
                        Potwierdzam bez zastrzeżeń wykonanie prac zgodnie z
                        umową
                      </LabelMedium>
                    ) : (
                      <></>
                    )}
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={
                        (item.id === "filesList" && hasHiddenFiles) ||
                        !data?.billIssuanceRequest?.files?.length
                          ? ""
                          : item.caption
                      }
                      disabled={isLoading || shouldDisableField(item.id)}
                      required={item.edit?.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (mutationError &&
                          mutationError.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          mutationError.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find((vE: InputValidationError) =>
                              item.id.includes("address")
                                ? vE?.property ===
                                  item.id.slice(7).toLowerCase()
                                : vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                    >
                      <>
                        {isFetching ? (
                          <Skeleton
                            rows={0}
                            height="36px"
                            width="100%"
                            animation
                          />
                        ) : item.type === FIELDS.CasesSelect ? (
                          <ControlledCasesSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.CurrencySelect ? (
                          <ControlledCurrencySelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="PLN"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.ContractAgreementSelect ? (
                          <ControlledContractsSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type ===
                          FIELDS.UsersOrganizationalUnitsSelect ? (
                          <ControlledUsersOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            objectId={user?.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.FilesList ? (
                          data?.billIssuanceRequest?.files && (
                            <FilesList
                              files={
                                billIssuanceRequestFilesData
                                  ?.billIssuanceRequest?.files
                              }
                              hasHiddenFiles={hasHiddenFiles}
                              selectedFiles={selectedFiles}
                              setSelectedFiles={setSelectedFiles}
                              disabled={shouldDisableField("files")}
                            />
                          )
                        ) : item.type === FIELDS.FilesPicker ? (
                          <ControlledFilesPicker
                            control={control}
                            name={item.id}
                            maxSize={134217728}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.TextArea ? (
                          <ControlledTextArea
                            control={control}
                            name={item.id}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : (
                          <ControlledInput
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={item.placeholder}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={
                              shouldDisableField(item.id) ||
                              (item.id === "sequenceNumber" &&
                                !checkPermission(
                                  PERMISSIONS.document.updateSequenceNumber
                                ))
                            }
                            masked={item.mask !== undefined}
                            mask={item.mask}
                            maskChar="_"
                            endEnhancer={
                              item?.endEnhancer === "PLN"
                                ? watchCurrency?.[0]?.code || item?.endEnhancer
                                : item?.endEnhancer
                            }
                          />
                        )}
                      </>
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji wniosku o wystawienie umowy cywilno-prawnej"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isFilesRevealConfirmDialogOpen}
              type={ConfirmDialogType.FilesReveal}
              close={() => setIsFilesRevealConfirmDialogOpen(false)}
              confirm={() => onFilesRevealSubmit()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
