import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import FilesList from "../../../../components/files-list";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import {
  ControlledCasesSelect,
  ControlledDocumentsSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledOrganizationalUnitUsersSelect,
  ControlledRequestFormsSelect,
  ControlledUsersOrganizationalUnitsSelect,
  ControlledUsersSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import { HIDDEN_FILE } from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { AssignmentLocationState } from "../../../Folders/folders";
import { DOCUMENTS_FIELDS } from "../../documents.form";
import { FILES_REVEAL } from "../../documents.gql";
import {
  REQUEST_FORM_NOTE_FIELDS,
  RequestFormNoteInputs,
} from "../request-form-note.form";
import {
  REQUEST_FORM_NOTE_FILES_SHOW,
  REQUEST_FORM_NOTE_SHOW,
  REQUEST_FORM_NOTE_UPDATE,
} from "../request-form-note.gql";

export default function DocumentsEditRequestFormNote(): React.ReactElement {
  const [
    isFilesRevealConfirmDialogOpen,
    setIsFilesRevealConfirmDialogOpen,
  ] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([]);
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading, isFetching, setIsFetching } = useLoading();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
  } = useForm<RequestFormNoteInputs>();
  const { user } = useAuth();

  const watchOrganizationalUnit = watch("organizationalUnit");
  const watchSenderWhenUser = watch("senderWhenUser");

  const { data, error: queryError } = useQuery(REQUEST_FORM_NOTE_SHOW, {
    variables: { id: documentId },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const {
    data: requestFormNoteFilesData,
    refetch: requestFormNoteFilesRefetch,
    error: requestFormNoteFilesError,
  } = useQuery(REQUEST_FORM_NOTE_FILES_SHOW, {
    variables: { id: documentId },
    fetchPolicy: "no-cache",
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const hasHiddenFiles =
    !!requestFormNoteFilesData?.requestFormNote?.files?.length &&
    requestFormNoteFilesData?.requestFormNote?.files[0]?.__typename ===
      HIDDEN_FILE;

  const [updateRequestFormNote, { error: mutationError }] = useMutation(
    REQUEST_FORM_NOTE_UPDATE,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    description,
    sequenceNumber,
    requestFormId,
    documentNumber,
    files,
    organizationalUnit,
    senderWhenUser,
  }: RequestFormNoteInputs): Promise<void> => {
    setIsLoading(true);

    const { requestFormNote } = data;

    try {
      await updateRequestFormNote({
        variables: {
          requestFormNoteUpdateInput: {
            id: documentId,
            userId: user?.id,
            ...(requestFormId?.[0]?.id !== requestFormNote?.requestFormId && {
              requestFormId: requestFormId && requestFormId[0]?.id,
            }),
            ...(sequenceNumber !== requestFormNote?.sequenceNumber && {
              sequenceNumber,
            }),
            ...(documentNumber !== requestFormNote?.documentNumber && {
              documentNumber,
            }),
            ...(description !== requestFormNote?.description && {
              description,
            }),
            ...(senderWhenUser?.[0]?.id !== requestFormNote?.senderId && {
              senderId: senderWhenUser && senderWhenUser[0]?.id,
            }),
            ...(organizationalUnit?.[0]?.id !==
              requestFormNote?.organizationalUnit?.id && {
              organizationalUnitId:
                organizationalUnit && organizationalUnit[0]?.id,
            }),
            deleteFilesIds: selectedFiles.length ? selectedFiles : null,
          },
          requestFormNoteFiles: files,
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.goBack();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onFilesRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealFiles({
        variables: {
          documentId,
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      requestFormNoteFilesRefetch();
      setIsFilesRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsFilesRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (requestFormNoteFilesError?.graphQLErrors)
      enqueueSnackbar({
        message: (requestFormNoteFilesError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [requestFormNoteFilesError]);

  useEffect(() => {
    if (data?.requestFormNote?.currentAssignment) {
      setAssignment(data?.requestFormNote?.currentAssignment);
      setIsAssignmentActive(true);
    }
    if (data?.requestFormNote) {
      setIsFetching(false);
      setValue("documentNumber", data?.requestFormNote?.documentNumber);
      setValue("sequenceNumber", data?.requestFormNote?.sequenceNumber);
      setValue("description", data?.requestFormNote?.description);
      if (data?.requestFormNote?.case)
        setValue("caseName", [
          {
            id: data?.document?.case?.id,
            label: `[${data?.document?.case?.number}] ${data?.document?.case?.name}`,
          },
        ]);
      if (data?.requestFormNote?.requestForm?.id)
        setValue("requestFormId", [
          {
            id: data?.requestFormNote?.requestForm?.id,
          },
        ]);
    }
    setValue("organizationalUnit", [
      {
        id: data?.requestFormNote?.organizationalUnit.id,
        label: `[
      ${data?.requestFormNote?.organizationalUnit?.symbol}
    ] ${data?.requestFormNote?.organizationalUnit?.name}`,
      },
    ]);
    if (data?.requestFormNote?.sender)
      setValue("senderWhenUser", [
        {
          id: data?.requestFormNote?.sender?.id,
          label: `${data?.requestFormNote?.sender?.firstName} ${data?.requestFormNote?.sender?.lastName}`,
          ...data?.requestFormNote?.sender,
        },
      ]);
  }, [data]);

  useEffect(() => {
    if (errors) scrollOnError(errors, DOCUMENTS_FIELDS, "edit");
  }, [errorTimeStamp]);

  const shouldDisableField = (fieldName: string): boolean =>
    !!data?.requestFormNote?.currentStatus?.documentFlowStep &&
    !data?.requestFormNote?.currentStatus?.documentFlowStep?.allowedDocumentFields?.includes(
      fieldName
    ) &&
    !data?.requestFormNote?.currentStatus?.documentFlowStep
      ?.isAllowedToEditAllFields;

  if (
    data &&
    data?.requestFormNote &&
    (!checkPermission(PERMISSIONS.requestFormNote.update) ||
      !data?.requestFormNote?.isEditable)
  )
    <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.requestFormNote?.internalNumber}
        labels={["Dokumenty", "Edytowanie notatki do wniosku"]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "editRequestFormNote",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={4}>
            <FormControl label="Numer" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>
                  {data?.requestFormNote?.internalNumber}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={8}>
            <FormControl label="Data rejestracji" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType="datetime">
                  {data?.requestFormNote?.createdAt}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form id="editRequestFormNote" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {REQUEST_FORM_NOTE_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit?.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                      height="20px"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <LabelMedium>
                          {!!data?.requestFormNote?.files?.length &&
                          group.id === "files"
                            ? `${group.label} (${data?.requestFormNote?.files?.length})`
                            : group.label}
                        </LabelMedium>
                        {hasHiddenFiles && group.id === "files" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setIsFilesRevealConfirmDialogOpen(true)
                            }
                            isLoading={revealFilesLoading || isFetching}
                            type="button"
                          >
                            Pokaż pliki
                          </Button>
                        )}
                      </Block>
                      {!hasHiddenFiles &&
                        !!data?.requestFormNote?.files?.length &&
                        group.id === "files" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "13px" }}
                          >
                            Dostęp jest aktywny do końca trwania sesji
                          </FormattedValue>
                        )}
                    </Block>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </>
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit?.visible)
                .map((item, index) => (
                  <Cell
                    span={item.span || 6}
                    skip={item.skip || 0}
                    key={group.id + `-field` + index}
                  >
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={
                        (item.id === "filesList" && hasHiddenFiles) ||
                        !data?.requestFormNote?.files?.length
                          ? ""
                          : item.caption
                      }
                      required={item.edit.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (mutationError &&
                          mutationError.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          mutationError.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find((vE: InputValidationError) =>
                              item.id.includes("address")
                                ? vE?.property ===
                                  item.id.slice(7).toLowerCase()
                                : vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading || shouldDisableField(item.id)}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="36px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.OrganizationalUnitUsersSelect ? (
                        watchOrganizationalUnit &&
                        watchOrganizationalUnit.length > 0 ? (
                          <ControlledOrganizationalUnitUsersSelect
                            objectId={watchOrganizationalUnit[0].id}
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : (
                          <ControlledUsersSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        )
                      ) : item.type ===
                        FIELDS.UsersOrganizationalUnitsSelect ? (
                        watchSenderWhenUser &&
                        watchSenderWhenUser.length > 0 ? (
                          <ControlledUsersOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            objectId={watchSenderWhenUser[0].id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : (
                          <ControlledOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        )
                      ) : item.type === FIELDS.RequestFormsSelect ? (
                        <ControlledRequestFormsSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.CasesSelect ? (
                        <ControlledCasesSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.DocumentsSelect ? (
                        <ControlledDocumentsSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.FilesPicker ? (
                        <ControlledFilesPicker
                          control={control}
                          name={item.id}
                          maxSize={134217728}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.FilesList ? (
                        <FilesList
                          files={
                            requestFormNoteFilesData?.requestFormNote?.files
                          }
                          hasHiddenFiles={hasHiddenFiles}
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={shouldDisableField(item.id)}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          masked={item.mask !== undefined}
                          mask={item.mask}
                          maskChar="_"
                          endEnhancer={item.endEnhancer}
                          disabled={
                            shouldDisableField(item.id) ||
                            (item.id === "sequenceNumber" &&
                              !checkPermission(
                                PERMISSIONS.document.updateSequenceNumber
                              ))
                          }
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji notatki do wniosku"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isFilesRevealConfirmDialogOpen}
              type={ConfirmDialogType.FilesReveal}
              close={() => setIsFilesRevealConfirmDialogOpen(false)}
              confirm={() => onFilesRevealSubmit()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
