import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import FilesList from "../../../../components/files-list";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import {
  ControlledCasesSelect,
  ControlledCurrencySelect,
  ControlledDictionaryValuesSelect,
  ControlledRequestFormsSelect,
  ControlledUsersOrganizationalUnitsSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import { HIDDEN_FILE } from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { AssignmentLocationState } from "../../../Folders/folders";
import { DocumentLink } from "../../documents";
import { FILES_REVEAL } from "../../documents.gql";
import {
  CONTRACT_PREPARATION_REQUEST_FIELDS,
  ContractPreparationRequestInputs,
} from "../contract-preparation-request.form";
import {
  CONTRACT_PREPARATION_REQUEST_EDIT,
  CONTRACT_PREPARATION_REQUEST_FILES_SHOW,
  CONTRACT_PREPARATION_REQUEST_SHOW,
} from "../contract-preparation-request.gql";

export default function DocumentsEditContractPreparationRequest(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([]);
  const [
    isFilesRevealConfirmDialogOpen,
    setIsFilesRevealConfirmDialogOpen,
  ] = useState(false);
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const { user } = useAuth();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    setValue,
    watch,
  } = useForm<ContractPreparationRequestInputs>();

  const watchBeginAtDate = watch("beginAt");
  const watchEndAtDate = watch("endAt");
  const watchCurrency = watch("currencies");

  const { data, error: queryError } = useQuery(
    CONTRACT_PREPARATION_REQUEST_SHOW,
    {
      variables: { id: documentId },
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );
  const {
    data: contractPreparationRequestFilesData,
    refetch: contractPreparationRequestFilesRefetch,
    error: contractPreparationRequestFilesError,
  } = useQuery(CONTRACT_PREPARATION_REQUEST_FILES_SHOW, {
    variables: { id: documentId },
    fetchPolicy: "no-cache",
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const hasHiddenFiles =
    !!contractPreparationRequestFilesData?.contractPreparationRequest?.files
      ?.length &&
    contractPreparationRequestFilesData?.contractPreparationRequest?.files[0]
      ?.__typename === HIDDEN_FILE;

  const [
    updateContractPreparationRequest,
    { error: mutationError, loading },
  ] = useMutation(CONTRACT_PREPARATION_REQUEST_EDIT, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    parentIds,
    contractorName,
    contractorPesel,
    serviceType,
    contractTypes,
    beginAt,
    endAt,
    grossFlatRate,
    grossHourlyRate,
    otherGrossRates,
    sequenceNumber,
    isOrganizationCoveringTravelExpenses,
    isOrganizationCoveringMaterialCosts,
    isRequiringDataProcessingAuthorization,
    isGrossValueIncreasedBySocialInsurance,
    files,
    organizationalUnits,
    currencies,
  }: ContractPreparationRequestInputs): Promise<void> => {
    setIsLoading(true);

    const { contractPreparationRequest } = data;
    const formContractorPesel = contractorPesel?.toString().replaceAll(" ", "");
    const formGrossFlatRate = parseFloat(grossFlatRate?.toString());
    const formGrossHourlyRate = parseFloat(grossHourlyRate?.toString());

    try {
      await updateContractPreparationRequest({
        variables: {
          contractPreparationRequestUpdateInput: {
            id: documentId,
            userId: user?.id,
            parentIds: parentIds?.length ? parentIds.map(({ id }) => id) : null,
            ...(contractorName !==
              contractPreparationRequest?.contractorName && {
              contractorName,
            }),
            ...(formContractorPesel !==
              contractPreparationRequest?.contractorPesel && {
              contractorPesel: contractorPesel?.toString().replaceAll(" ", ""),
            }),
            ...(serviceType !== contractPreparationRequest?.serviceType && {
              serviceType,
            }),
            ...(contractTypes?.[0]?.label !==
              contractPreparationRequest?.contractType && {
              contractType: contractTypes && contractTypes[0]?.label,
            }),
            ...(sequenceNumber !==
              contractPreparationRequest?.sequenceNumber && {
              sequenceNumber,
            }),
            ...(new Date(beginAt as Date).toISOString() !==
              new Date(contractPreparationRequest?.beginAt).toISOString() && {
              beginAt,
            }),
            ...(new Date(endAt as Date).toISOString() !==
              new Date(contractPreparationRequest?.endAt).toISOString() && {
              endAt,
            }),
            ...(formGrossFlatRate !==
              contractPreparationRequest?.formGrossFlatRate && {
              grossFlatRate: formGrossFlatRate,
            }),
            ...(formGrossHourlyRate !==
              contractPreparationRequest?.grossHourlyRate && {
              grossHourlyRate: formGrossHourlyRate,
            }),
            ...(otherGrossRates !==
              contractPreparationRequest?.otherGrossRates && {
              otherGrossRates,
            }),
            ...(isOrganizationCoveringTravelExpenses !==
              contractPreparationRequest?.isOrganizationCoveringTravelExpenses?.toString() && {
              isOrganizationCoveringTravelExpenses: !!isOrganizationCoveringTravelExpenses,
            }),
            ...(isOrganizationCoveringMaterialCosts !==
              contractPreparationRequest?.isOrganizationCoveringMaterialCosts?.toString() && {
              isOrganizationCoveringMaterialCosts: !!isOrganizationCoveringMaterialCosts,
            }),
            ...(isRequiringDataProcessingAuthorization !==
              contractPreparationRequest?.isRequiringDataProcessingAuthorization?.toString() && {
              isRequiringDataProcessingAuthorization: !!isRequiringDataProcessingAuthorization,
            }),
            ...(isGrossValueIncreasedBySocialInsurance !==
              contractPreparationRequest?.isGrossValueIncreasedBySocialInsurance?.toString() && {
              isGrossValueIncreasedBySocialInsurance: !!isGrossValueIncreasedBySocialInsurance,
            }),
            ...(organizationalUnits?.[0]?.id !==
              contractPreparationRequest?.organizationalUnit?.id && {
              organizationalUnitId:
                organizationalUnits && organizationalUnits[0]?.id,
            }),
            ...(currencies?.[0]?.id !==
              contractPreparationRequest?.currencyId?.id && {
              currencyId: currencies?.[0]?.currencyId || null,
            }),
            deleteFilesIds: selectedFiles.length ? selectedFiles : null,
          },

          files,
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.goBack();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (contractPreparationRequestFilesError?.graphQLErrors)
      enqueueSnackbar({
        message: (contractPreparationRequestFilesError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [contractPreparationRequestFilesError]);

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onFilesRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealFiles({
        variables: {
          documentId,
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      contractPreparationRequestFilesRefetch();
      setIsFilesRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsFilesRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (data?.contractPreparationRequest?.currentAssignment) {
      setAssignment(data?.contractPreparationRequest?.currentAssignment);
      setIsAssignmentActive(true);
    }
    if (data?.contractPreparationRequest) {
      if (data?.contractPreparationRequest?.documentLinks?.length)
        setValue(
          "parentIds",
          data?.contractPreparationRequest?.documentLinks
            ?.filter(
              ({ type, linkerId }: DocumentLink) =>
                type === "Parent" && parseInt(id as string) === linkerId
            )
            .map(({ linked, linker }: DocumentLink) =>
              parseInt(id as string) === linker.id ? linked : linker
            )
        );
      data?.contractPreparationRequest?.organizationalUnit &&
        setValue("organizationalUnits", [
          { id: data?.contractPreparationRequest?.organizationalUnit?.id },
        ]);
      data?.contractPreparationRequest?.case &&
        setValue("cases", [{ id: data?.contractPreparationRequest?.case?.id }]);
      setValue(
        "contractorName",
        data?.contractPreparationRequest?.contractorName
      );
      setValue(
        "contractorPesel",
        data?.contractPreparationRequest?.contractorPesel
      );
      setValue(
        "sequenceNumber",
        data?.contractPreparationRequest?.sequenceNumber
      );

      data?.contractPreparationRequest?.currency &&
        setValue("currencies", [
          {
            id: data?.contractPreparationRequest?.currency?.code,
          },
        ]);
      setValue("serviceType", data?.contractPreparationRequest?.serviceType);
      setValue("beginAt", new Date(data?.contractPreparationRequest?.beginAt));
      setValue("endAt", new Date(data?.contractPreparationRequest?.endAt));
      setValue(
        "grossFlatRate",
        data?.contractPreparationRequest?.grossFlatRate
      );
      setValue(
        "grossHourlyRate",
        data?.contractPreparationRequest?.grossHourlyRate
      );
      setValue(
        "otherGrossRates",
        data?.contractPreparationRequest?.otherGrossRates
      );
      setValue("contractTypes", [
        { id: data?.contractPreparationRequest?.contractType },
      ]);
      setValue(
        "isOrganizationCoveringTravelExpenses",
        data?.contractPreparationRequest?.isOrganizationCoveringTravelExpenses
      );
      setValue(
        "isOrganizationCoveringMaterialCosts",
        data?.contractPreparationRequest?.isOrganizationCoveringMaterialCosts
      );
      setValue(
        "isRequiringDataProcessingAuthorization",
        data?.contractPreparationRequest?.isRequiringDataProcessingAuthorization
      );
      setValue(
        "isGrossValueIncreasedBySocialInsurance",
        data?.contractPreparationRequest?.isGrossValueIncreasedBySocialInsurance
      );
      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    if (errors)
      scrollOnError(errors, CONTRACT_PREPARATION_REQUEST_FIELDS, "edit");
  }, [errorTimeStamp]);

  const shouldDisableField = (fieldName: string): boolean =>
    !!data?.contractPreparationRequest?.currentStatus?.documentFlowStep &&
    !data?.contractPreparationRequest?.currentStatus?.documentFlowStep?.allowedDocumentFields?.includes(
      fieldName
    ) &&
    data?.contractPreparationRequest?.currentStatus?.documentFlowStep
      ?.isAllowedToEditAllFields;

  if (
    data &&
    data?.contractPreparationRequest &&
    (!checkPermission(PERMISSIONS.contractPreparationRequest.update) ||
      !data?.contractPreparationRequest?.isEditable)
  )
    <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.contractPreparationRequest?.internalNumber}
        labels={["Dokumenty", "Edytowanie"]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              !(Object.keys(dirtyFields).length <= 1)
                ? setIsCancelConfirmDialogOpen(true)
                : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "editContractPreparationRequest",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={4}>
            <FormControl label="Numer" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>
                  {data?.contractPreparationRequest?.internalNumber}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={8}>
            <FormControl label="Data rejestracji" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType="datetime">
                  {data?.contractPreparationRequest?.createdAt}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form
          id="editContractPreparationRequest"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid>
            {CONTRACT_PREPARATION_REQUEST_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit?.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                      height="20px"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <LabelMedium>
                          {!!data?.contractPreparationRequest?.files?.length &&
                          group.id === "files"
                            ? `${group.label} (${data?.contractPreparationRequest?.files?.length})`
                            : group.label}
                        </LabelMedium>
                        {hasHiddenFiles && group.id === "files" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setIsFilesRevealConfirmDialogOpen(true)
                            }
                            isLoading={revealFilesLoading || isFetching}
                            type="button"
                          >
                            Pokaż pliki
                          </Button>
                        )}
                      </Block>
                      {!hasHiddenFiles &&
                        !!data?.contractPreparationRequest?.files?.length &&
                        group.id === "files" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "13px" }}
                          >
                            Dostęp jest aktywny do końca trwania sesji
                          </FormattedValue>
                        )}
                    </Block>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </>
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit?.visible)
                .map((item, index) => (
                  <Cell
                    span={item.span || 6}
                    skip={item.skip || 0}
                    key={group.id + `-field` + index}
                  >
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={
                        (item.id === "filesList" && hasHiddenFiles) ||
                        !data?.contractPreparationRequest?.files?.length
                          ? ""
                          : item.caption
                      }
                      disabled={isLoading || shouldDisableField(item.id)}
                      required={item.edit?.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (mutationError &&
                          mutationError.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          mutationError.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find((vE: InputValidationError) =>
                              item.id.includes("address")
                                ? vE?.property ===
                                  item.id.slice(7).toLowerCase()
                                : vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                    >
                      <>
                        {isFetching ? (
                          <Skeleton
                            rows={0}
                            height="36px"
                            width="100%"
                            animation
                          />
                        ) : item.type === FIELDS.CasesSelect ? (
                          <ControlledCasesSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.RequestFormsSelect ? (
                          <ControlledRequestFormsSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            withFlowEnded
                            rules={{
                              ...(item.edit.required && {
                                required: formValidation.messages.required,
                              }),
                            }}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.DictionaryValuesSelect ? (
                          <ControlledDictionaryValuesSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            dictionarySystemName="contractPreparationRequest.types"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type ===
                          FIELDS.UsersOrganizationalUnitsSelect ? (
                          <ControlledUsersOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            objectId={user?.id}
                            placeholder="Wybierz"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.DatePicker ? (
                          <ControlledDatePicker
                            name={item.id}
                            control={control}
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            {...(item.id === "endAt" && {
                              minDate: watchBeginAtDate as Date,
                            })}
                            {...(item.id === "beginAt" && {
                              maxDate: watchEndAtDate as Date,
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.FilesList ? (
                          data?.contractPreparationRequest?.files && (
                            <FilesList
                              files={
                                contractPreparationRequestFilesData
                                  ?.contractPreparationRequest?.files
                              }
                              hasHiddenFiles={hasHiddenFiles}
                              selectedFiles={selectedFiles}
                              setSelectedFiles={setSelectedFiles}
                              disabled={shouldDisableField(item.id)}
                            />
                          )
                        ) : item.type === FIELDS.CurrencySelect ? (
                          <ControlledCurrencySelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="PLN"
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.FilesPicker ? (
                          <ControlledFilesPicker
                            control={control}
                            name={item.id}
                            maxSize={134217728}
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : item.type === FIELDS.Checkbox ? (
                          <div>
                            <ControlledCheckbox
                              control={control}
                              name={item.id}
                              disabled={loading || shouldDisableField(item.id)}
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                              {...((item.info === "firstCheckbox" ||
                                item.info === "lastCheckbox") && {
                                $style: {
                                  [item.info === "firstCheckbox"
                                    ? "marginTop"
                                    : "marginBottom"]: "40px",
                                },
                              })}
                            >
                              {item.label}
                            </ControlledCheckbox>
                          </div>
                        ) : item.type === FIELDS.TextArea ? (
                          <ControlledTextArea
                            control={control}
                            name={item.id}
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={shouldDisableField(item.id)}
                          />
                        ) : (
                          <ControlledInput
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={item.placeholder}
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            disabled={
                              shouldDisableField(item.id) ||
                              (item.id === "sequenceNumber" &&
                                !checkPermission(
                                  PERMISSIONS.document.updateSequenceNumber
                                ))
                            }
                            masked={item.mask !== undefined}
                            mask={item.mask}
                            maskChar="_"
                            endEnhancer={
                              item?.endEnhancer === "PLN"
                                ? watchCurrency?.[0]?.code || item?.endEnhancer
                                : item?.endEnhancer
                            }
                            {...(item.type === FIELDS.NumberInput && {
                              type: "number",
                            })}
                          />
                        )}
                      </>
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji wniosku o wystawienie umowy cywilno-prawnej"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isFilesRevealConfirmDialogOpen}
              type={ConfirmDialogType.FilesReveal}
              close={() => setIsFilesRevealConfirmDialogOpen(false)}
              confirm={() => onFilesRevealSubmit()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
